// import CustomDropDown from "components/CustomDropDown/CustomDropDown";
import Drawer from "react-modern-drawer";
import { LoadingButton, User } from "../../assets/svg/SVG";
import CustomDropDown from "../CustomDropDown/CustomDropDown";
const RoleForm = ({
  name,
  drawerName,
  isOpen,
  toggleDrawer,
  handleSubmit,
  setName,
  updatedPermission,
  permissionId,
  setPermissionId,
  roleLoading,
}) => {
  return (
    <Drawer open={isOpen} onClose={toggleDrawer} direction="right" size="553px">
      <div className="px-12 pt-12">
        <h4 className="flex items-center gap-x-[10px] text-lg font-bold">
          <User /> <span>{drawerName}</span>{" "}
        </h4>
        <hr className="mt-3 mb-[45px] border-[1px] bg-[#E0E0E0]" />
        <form onSubmit={(e) => handleSubmit(e)} className="text-[#2C3C57]">
          <label className="text-sm font-semibold flex items-center">
            Name
            <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
          </label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            className="border-[1px] w-full  py-[10px] px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
            placeholder="Name"
          />
          <label className="text-sm font-semibold flex items-center">
            Select Permission
            <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
          </label>
          <CustomDropDown
            closeMenuOnSelect={false}
            value={permissionId}
            onChange={(value) => setPermissionId(value)}
            options={updatedPermission?.map((option) => {
              return { value: option?.value, label: option?.label };
            })}
            isMulti
            placeholder="Select Permission"
            className="border-[1px] w-full   rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
          />
          <div className="text-center mt-6">
            <button
              disabled={roleLoading}
              className={` ${
                roleLoading && "cursor-not-allowed"
              } flex items-center justify-center bg-[#2C4DFF] text-white active:bg-blueGray-600 text-sm font-semibold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
              type="submit"
            >
              {roleLoading && <LoadingButton customStyle="text-white" />}
              <span className="ml-5">{drawerName}</span>
            </button>
          </div>
        </form>
      </div>
    </Drawer>
  );
};

export default RoleForm;
