import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import { MoneyBag } from "../../assets/svg/SVG";
import LoadingIconGray from "../Common/LoadingGray";

const PaymentDetails = ({
  paymentDetailsLoading,
  paymentDetails,

  isOpen,
  toggleDrawer,
}) => {
  const [copied, setCopied] = useState("");
  console.log("paymentDetails :", paymentDetails);
  //!  text copy  function
  const handleTextCopy = (value) => {
    navigator.clipboard.writeText(value);
    setCopied(value);
  };

  const handleTruncat = (text, len) => {
    return text.substring(0, len) + "...";
  };

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        size="900px"
        className="p-[49px] !overflow-y-scroll"
      >
        {paymentDetailsLoading ? (
          <div className="flex items-center justify-center h-screen">
            <LoadingIconGray />
          </div>
        ) : (
          <div>
            <div className="flex">
              <MoneyBag />
              <h3 className="pl-[13px] font-bold text-[17px]">All Details</h3>
            </div>
            <div className="grid grid-cols-2 gap-6 mb-[19px] pt-[25px]">
              <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                  Payment Details
                </h3>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Total Amount</p>
                  <p className="text-sm">${paymentDetails?.amount}</p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="text-sm whitespace-nowrap">Transaction ID</p>
                  <p className="text-sm flex">
                    <span
                      className={`truncate w-full ${
                        copied === paymentDetails?.transaction_id &&
                        "text-blue-500"
                      }`}
                    >
                      {paymentDetails?.transaction_id?.length > 20
                        ? handleTruncat(paymentDetails?.transaction_id, 20)
                        : paymentDetails?.transaction_id}
                    </span>
                    <span className="pl-5">
                      <img
                        src="https://fundednext.fra1.digitaloceanspaces.com/dashboard/copy_icon.svg?auto=format&fit=max&w=32/copy_icon.svg"
                        alt="copy_icon"
                        onClick={() =>
                          handleTextCopy(paymentDetails?.transaction_id)
                        }
                        className="cursor-pointer"
                      />
                    </span>
                  </p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Reference</p>
                  <p className="text-sm">{paymentDetails?.reference}</p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Created at</p>
                  <p className="text-sm">{paymentDetails?.created_at}</p>
                </div>
              </div>
              <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                  Customer Details
                </h3>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Name</p>
                  <p className="text-sm">{paymentDetails?.customer?.name}</p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Email</p>
                  <p className="text-sm">{paymentDetails?.customer?.email}</p>
                </div>
              </div>
            </div>
            {paymentDetails?.extra_charge.length > 0 && (
              <div className="mb-[19px] shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                  Extra Charge
                </h3>
                <div className=" mb-6">
                  <table className="w-full text-left -mt-2 border-collapse">
                    <thead>
                      <tr className=" text-black text-sm  whitespace-nowrap">
                        <th className="py-[18px]">Payment Id</th>
                        <th className="py-[18px]">Amount</th>
                        <th className="py-[18px]">Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentDetails?.extra_charge?.map((item, idx) => (
                        <tr key={idx} className="pb-2 mb-3">
                          <td className="py-[10px] text-sm text-[#494949]">
                            {item?.payment_id}
                          </td>
                          <td className="py-[10px] text-sm text-[#494949]">
                            {item?.amount}
                          </td>
                          <td className="py-[10px] text-sm text-[#494949]">
                            {item?.remarks}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {paymentDetails?.customer_card && (
              <div className="mb-[19px] shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                  Card Details
                </h3>
                <div className="grid grid-cols-2 gap-20 mb-6">
                  <div>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Card type:
                      </p>
                      <p className="text-sm">
                        {paymentDetails?.customer_card?.card_type}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Card year:
                      </p>
                      <p className="text-sm">
                        {paymentDetails?.customer_card?.card_year}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Card month:
                      </p>
                      <p className="text-sm">
                        {paymentDetails?.customer_card?.card_month}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Card last four digit:
                      </p>
                      <p className="text-sm">
                        {paymentDetails?.customer_card?.card_last_four_digit}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Gateway Name:
                      </p>
                      <p className="text-sm">
                        {paymentDetails?.payment_gateway?.name}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Status:
                      </p>
                      <p className="text-sm">
                        {paymentDetails?.customer_card?.status == 1
                          ? "Enable"
                          : "Disable"}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Created at:
                      </p>
                      <p className="text-sm ">
                        {paymentDetails?.customer_card?.created_at}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Updated at :
                      </p>
                      <p className="text-sm">
                        {paymentDetails?.customer_card?.updated_at}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {paymentDetails?.refunds?.length > 0 && (
              <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px] mb-[19px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold">
                  Refund Details
                </h3>
                <table className="w-full text-left -mt-2 border-collapse">
                  <thead>
                    <tr className=" text-black text-sm  whitespace-nowrap ">
                      <th className="py-[18px]">Refunded By</th>
                      <th className="py-[18px]">Amount</th>
                      <th className="py-[18px]">Comment</th>
                      <th className="py-[18px]">Status</th>
                      <th className="py-[18px]">Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentDetails?.refunds?.map((refund, idx) => (
                      <tr
                        key={idx}
                        className="border-b-[1px]  pb-2 border-[#DBDBDB]"
                      >
                        <td className="py-[10px] text-sm text-[#494949]">
                          {refund?.refunded_by?.name}
                        </td>
                        <td className="py-[10px] text-sm text-[#494949]">
                          ${refund?.amount}
                        </td>
                        <td className="py-[10px] text-sm text-[#494949]">
                          {refund?.comment}
                        </td>
                        <td className="py-[10px] text-sm ">
                          {refund?.status == 0 && "Pending"}
                          {refund?.status == 1 && "Approved"}
                          {refund?.status == 2 && "Declined"}
                        </td>
                        <td className="py-[10px] text-sm ">
                          {refund?.created_at}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="grid grid-cols-2 gap-6 mb-[19px]">
              <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                  Project
                </h3>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Name</p>
                  <p className="text-sm">{paymentDetails?.project?.name}</p>
                </div>
              </div>
              <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                  Payment Gate
                </h3>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Name</p>
                  <p className="text-sm">
                    {paymentDetails?.payment_gateway?.name}
                  </p>
                </div>

                {/^partial/.test(paymentDetails?.transaction_id) ? (
                  <div className="flex justify-between mb-3">
                    <p className="text-sm">Remarks</p>
                    <p className="text-sm">
                      {paymentDetails?.parent_payments[0]?.pivot?.remarks ||
                        "N/A"}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {paymentDetails?.partial_payments.length > 0 && (
              <div className="mb-16 shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                  Partial Payment
                </h3>
                <div className=" mb-6">
                  <table className="w-full text-left -mt-2 border-collapse">
                    <thead>
                      <tr className=" text-black text-sm  whitespace-nowrap">
                        <th className="py-[18px]">Amount</th>
                        <th className="py-[18px]">Transaction ID</th>
                        <th className="py-[18px]">Remarks</th>

                        <th className="py-[18px]">Created at</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentDetails?.partial_payments?.map((item, idx) => (
                        <tr key={idx} className="pb-2 mb-3">
                          <td className="py-[10px] text-sm text-[#494949]">
                            ${item?.amount}
                          </td>
                          <td className="py-[10px] text-sm text-[#494949]">
                            {item?.transaction_id}
                          </td>
                          <td className="py-[10px] text-sm text-[#494949]">
                            {item?.pivot?.remarks || "N/A"}
                          </td>
                          <td className="py-[10px] text-sm text-[#494949]">
                            {item?.created_at}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default PaymentDetails;
