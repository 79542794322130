import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../../components/Common/Spinner";
import GatewayForm from "../../components/Gateway/GatewayForm";

const PaymentGateway = ({ allPermission }) => {
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [status, setStatus] = useState();
  const [allGateway, setAllGateway] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [editId, setEditId] = useState();
  const [links, setLinks] = useState([]);
  const [isEdit, setIsEdit] = useState();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);

    if (isEdit) {
      setEditId();
      setName("");
      setDescription("");
      setStatus();
      setIsEdit(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isEdit) {
      var urlencoded = new URLSearchParams();
      urlencoded.append("name", name);
      urlencoded.append("description", description);
      urlencoded.append("status", status);
    } else {
      var formdata = new FormData();
      formdata.append("name", name);
      formdata.append("description", description);
      formdata.append("status", status);
    }

    var requestOptions = {
      method: isEdit ? "PUT" : "POST",
      body: isEdit ? urlencoded : formdata,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let res = await fetch(
        isEdit
          ? `${process.env.REACT_APP_BASE_URL}/payment-gateway/${editId}`
          : `${process.env.REACT_APP_BASE_URL}/payment-gateway`,
        requestOptions
      );

      const data = await res.json();

      if (res?.status !== 422) {
        toggleDrawer();
        getAllPaymentGateway(
          `${process.env.REACT_APP_BASE_URL}/payment-gateway`
        );
        toast(data?.message);
      }
      if (res?.status === 422) {
        toggleDrawer();
        for (var key of Object.keys(data?.data)) {
          toast(`${data?.data[key]}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (gateway) => {
    setEditId(gateway?.id);
    setName(gateway?.name);
    setDescription(gateway?.description);
    setStatus(gateway?.status);
    toggleDrawer();
    setIsEdit(true);
  };

  const getAllPaymentGateway = async (url) => {
    setLoading(true);
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setAllGateway(data?.data?.payment_gateway?.data);
      setLinks(data?.data?.payment_gateway?.links);
      setLoading(false);
    } catch (error) {
      console.log("error :", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    token &&
      getAllPaymentGateway(`${process.env.REACT_APP_BASE_URL}/payment-gateway`);
  }, [token]);

  if (loading) return <Spinner />;

  return (
    <>
      <div className="w-full">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
          <div className="rounded-t mb-0  py-3">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className="text-2xl font-medium text-[rgba(0, 0, 0, 0.8)]">
                  Payment Gateway List
                </h3>
              </div>
            </div>
          </div>
          <div className="w-full overflow-x-auto mt-2 shadow-[0px_4px_45px_#F3F3F3]">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="mx-8 border-b text-[#0F223C] text-sm font-semibold whitespace-nowrap">
                  <th className="py-[23px] pl-5">Id</th>
                  <th className="py-[23px]">Name</th>
                  <th className="py-[23px]">Description</th>
                  <th className="py-[23px]">Status</th>
                  <th className="py-[23px]">Token</th>
                  <th className="py-[23px]">
                    {allPermission.includes("Payment Gateway Edit") && "Action"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {allGateway?.map((gateway, idx) => {
                  return (
                    <tr
                      key={idx}
                      className="border-b  border-[#EDEFF3] whitespace-nowrap text-sm hover:bg-gray-200"
                    >
                      <td className="py-7 pl-5">{gateway?.id}</td>
                      <td className="py-7"> {gateway?.name}</td>
                      <td className="py-7">{gateway?.description}</td>
                      <td className="py-7"> {gateway?.status}</td>
                      <td className="py-7"> {gateway?.token}</td>
                      <td className="py-7 pr-5">
                        {allPermission.includes("Payment Gateway Edit") && (
                          <button
                            onClick={(e) => handleEdit(gateway)}
                            className="text-white bg-[#0D86FF] rounded m-1 px-2 py-1"
                          >
                            Edit
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex justify-end items-center">
          {links?.map((item, idx) => (
            <button
              key={idx}
              onClick={(e) => getAllPaymentGateway(item.url)}
              className={`${
                item?.active &&
                " !bg-gradient-to-r from-[#588CFF] to-[#2C4DFF] !text-white"
              } first:rounded-r-none  first:w-auto first:px-2 last:px-2 last:w-auto w-[33px] rounded-md h-[33px] flex justify-center items-center last:rounded-l-none cursor-pointer border-2 bg-[#F1F1F1] py-2 text-[#767676] mx-1 border-[#F1F1F1]`}
              dangerouslySetInnerHTML={{
                __html: `${item.label}`,
              }}
            ></button>
          ))}
        </div>
        <GatewayForm
          isEdit={isEdit}
          drawerName={isEdit ? "Update Gateway" : "Create Gateway"}
          name={name}
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
          handleSubmit={handleSubmit}
          setName={setName}
          description={description}
          setDescription={setDescription}
          status={status}
          setStatus={setStatus}
        />
      </div>
    </>
  );
};

export default PaymentGateway;
