import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { ArrowRight, Dollars, Filter } from "../../assets/svg/SVG";
import LoadingIconGray from "../../components/Common/LoadingGray";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import RefundForm from "../../components/Refund/RefundForm";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Refund = ({ allPermission }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [refundList, setRefundList] = useState([]);
  const [links, setLinks] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [allData, setAllData] = useState();
  const [status, setStatus] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [refundId, setRefundId] = useState();
  const [showAction, setShowAction] = useState(false);
  const [refundLoading, setRefundLoading] = useState("");
  const [comment, setComment] = useState("");
  const [refundStatus, setRefundStatus] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [refundDetails, setRefundDetails] = useState();
  const [refundDetailsLoading, setRefundDetailsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Refund");
  const [paymentDetails, setPaymentDetails] = useState();

  const getRefundList = async (URL, isReset = false) => {
    setLoading(true);

    if (isFilter && !isReset) {
      if (status) {
        URL = URL + "status=" + status.value + "&";
      }
      if (startDate) {
        URL = URL + "startDate=" + startDate + "&";
      }
      if (endDate) {
        URL = URL + "endDate=" + endDate + "&";
      }

      URL = URL.slice(0, -1);
    }

    try {
      const response = await fetch(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      setAllData(result?.data);
      setRefundList(result?.data?.refunds?.data);

      setLinks(result?.data?.refunds?.links);

      setLoading(false);
    } catch (error) {
      console.log("error :", error);
      setLoading(false);
    }
  };

  const handleReset = () => {
    getRefundList(`${process.env.REACT_APP_BASE_URL}/refunds`, true);
  };

  const handleRefund = (id) => {
    setShowAction(!showAction);
    setRefundId(id);
    token && getRefundDetails(token, id);
  };

  const toggleDetailsDrawer = () => {
    setIsOpen((prevState) => !prevState);
    // setRefundId("");
    // setShowAction(false);
  };

  const handleSubmit = async (e) => {
    refundStatus == 1 && setRefundLoading("Approve");
    refundStatus == 2 && setRefundLoading("Decline");

    e.preventDefault();
    var formdata = new FormData();
    formdata.append("status", refundStatus);
    comment.length > 0 && formdata.append("comment", comment);
    formdata.append("id", refundId);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/refunds/action`,
        requestOptions
      );

      const data = await res.json();

      setRefundLoading("");

      if (res?.status !== 422) {
        toast(data?.message);
        toggleDetailsDrawer();
        setRefundId();
        setComment("");
        getRefundList(`${process.env.REACT_APP_BASE_URL}/refunds`, true);
      }
      if (res?.status === 422) {
        for (var key of Object.keys(data?.data)) {
          toast(`${data?.data[key]}`);
        }
      }
      setShowAction(!showAction);
    } catch (error) {
      console.error(error);
    }
  };

  const getRefundDetails = async (token, id) => {
    setRefundDetailsLoading(true);
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/refunds/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    let result = await res.json();
    if (result) {
      setRefundDetails(result?.data?.refund);
      setPaymentDetails(result?.data?.payment);
      setRefundDetailsLoading(false);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    token &&
      getRefundList(
        isFilter
          ? `${process.env.REACT_APP_BASE_URL}/refunds?`
          : `${process.env.REACT_APP_BASE_URL}/refunds`
      );
  }, [token]);

  return (
    <>
      <div className="w-full">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
          <div className="flex justify-between mb-4">
            <h3 className="text-2xl font-medium text-[#000000] flex items-center gap-x-5">
              <img src="/payment.svg" alt="" /> <span>List of Refund</span>
              <span className="text-sm -ml-2"></span>
            </h3>
            <div className="flex">
              <button
                onClick={() => setIsFilter(!isFilter)}
                className="border-[#2A7FFE] ml-4 border-[1px] flex items-center  text-[#646464] rounded text-[15px] font-medium hover:scale-105 ease-in-out delay-150 py-[8px] px-[17px]"
              >
                <Filter />
                <span className="mx-[10px] text-lg">Filter</span>
                <span className={`${isFilter ? "" : "rotate-180"}`}>
                  <ArrowRight />
                </span>
              </button>
            </div>
          </div>

          {isFilter && (
            <div className=" bg-[#F8F9FF] px-8 py-8 rounded-md  mb-10">
              <div className="flex items-center flex-wrap gap-y-4 2xl:gap-y-6">
                <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                  <CustomDropDown
                    value={status}
                    options={[
                      { value: 0, label: "Pending" },
                      { value: 1, label: "Approved" },
                      { value: 2, label: "Declined " },
                    ]}
                    onChange={(value) => setStatus(value)}
                    placeholder="Select Status"
                    className="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full ease-linear transition-all duration-150 border-[#D7D7D7] border-[1px] focus:outline-[#635bff]"
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                  <input
                    value={startDate}
                    type="text"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="px-3 py-[10px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
                    placeholder="Start Date"
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                  <input
                    value={endDate}
                    type="text"
                    onChange={(e) => setEndDate(e.target.value)}
                    className="px-3 py-[10px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    placeholder="End Date"
                  />
                </div>
                <div className="w-full mx-4 mt-5 flex gap-x-8">
                  <button
                    onClick={(e) =>
                      getRefundList(
                        `${process.env.REACT_APP_BASE_URL}/refunds?`
                      )
                    }
                    className="bg-[#2A7FFE] text-white text-sm px-7 py-2 rounded font-normal hover:scale-105 ease-in-out delay-150 flex justify-center items-center"
                  >
                    <SearchIcon />
                    <span className="ml-2 text-lg">Search Result</span>
                  </button>
                  <button
                    onClick={() => handleReset()}
                    className="bg-[#F2F2F2] text-[#3E3E3E] px-4 py-2 rounded text-sm hover:scale-105 ease-in-out delay-150 flex justify-center items-center"
                  >
                    <RestartAltIcon />
                    <span className="ml-2 text-lg">Reset</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          {!loading ? (
            <>
              <div className="w-full  pb-24 mt-2">
                <table className="w-full shadow-[0px_4px_45px_#F3F3F3] text-left border-collapse">
                  <thead>
                    <tr className="mx-8 border-b text-[#0F223C] text-sm font-semibold whitespace-nowrap">
                      <th className="py-[23px] pl-5">Project</th>
                      <th className="py-[23px] pr-4">Requested Reason </th>
                      <th className="py-[23px] pr-4">Transaction ID</th>
                      <th className="py-[23px] pr-4">Amount</th>
                      <th className="py-[23px] pr-4">Status</th>
                      <th className="py-[23px]">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {refundList?.map((item, idx) => {
                      return (
                        <tr
                          key={idx}
                          className="border-b border-[#EDEFF3]  text-sm  hover:bg-gray-200"
                        >
                          <td className="py-7 pl-5">
                            {" "}
                            {item?.payment?.project?.name}
                          </td>
                          <td className="py-7 px-4">
                            <span className="!max-w-[300px]">
                              {item?.request_comment}
                            </span>
                          </td>
                          <td className="py-7 pr-4">
                            {item?.payment?.transaction_id}
                          </td>
                          <td className="py-7 pr-4 text-red-500">
                            ${item?.amount}
                          </td>
                          <td className={``}>
                            {item?.status == 0 && (
                              <span className="text-[#FFA353] font-medium">
                                Pending
                              </span>
                            )}
                            {item?.status == 1 && (
                              <span className="text-[#2CAC66] font-medium">
                                Approved
                              </span>
                            )}
                            {item?.status == 2 && (
                              <span className="text-[#D8585B] font-medium">
                                Declined
                              </span>
                            )}
                          </td>
                          <td className="py-7">
                            <div className="flex items-center">
                              <div className="ml-2 flex">
                                <div className="flex justify-center items-center gap-4">
                                  {item?.isFailed != null && (
                                    <div className="w-4 h-4 bg-red-600 rounded-full"></div>
                                  )}
                                  <div className="relative">
                                    <button
                                      onClick={() => handleRefund(item?.id)}
                                      type="button"
                                      className={`
                                      ${
                                        refundId == item?.id &&
                                        showAction &&
                                        "text-gray-700 bg-gray-50"
                                      }
                                      inline-flex px-2 py-1 border-[1px] border-[#f0f0f0] rounded-md items-center justify-center h-full text-gray-600  hover:text-gray-700  hover:bg-gray-50`}
                                    >
                                      <LogoutIcon sx={{ fontSize: "20px" }} />
                                      <ArrowDropDownIcon />
                                    </button>

                                    <div
                                      className={`
                                      ${
                                        refundId == item?.id && showAction
                                          ? "visible"
                                          : "invisible"
                                      }
                                      absolute left-[-15px] z-10 w-32  mt-4 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg`}
                                    >
                                      <div className="p-2">
                                        <button
                                          onClick={toggleDetailsDrawer}
                                          className="block w-full cursor-pointer px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                                        >
                                          Details
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="flex justify-end items-center">
                {links?.map((item, idx) => (
                  <button
                    key={idx}
                    onClick={(e) =>
                      getRefundList(isFilter ? `${item.url}&` : item.url)
                    }
                    className={`${
                      item?.active &&
                      " !bg-gradient-to-r from-[#588CFF] to-[#2C4DFF] !text-white"
                    } first:rounded-r-none  first:w-auto first:px-2 last:px-2 last:w-auto w-[33px] rounded-md h-[33px] flex justify-center items-center last:rounded-l-none cursor-pointer border-2 bg-[#F1F1F1] py-2 text-[#767676] mx-1 border-[#F1F1F1]`}
                    dangerouslySetInnerHTML={{
                      __html: `${item.label}`,
                    }}
                  ></button>
                ))}
              </div>
            </>
          ) : (
            <LoadingIconGray />
          )}
        </div>

        <RefundForm
          refundDetails={refundDetails}
          isOpen={isOpen}
          toggleDrawer={toggleDetailsDrawer}
          handleSubmit={handleSubmit}
          comment={comment}
          setComment={setComment}
          refundStatus={refundStatus}
          setRefundStatus={setRefundStatus}
          refundLoading={refundLoading}
          refundDetailsLoading={refundDetailsLoading}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          paymentDetails={paymentDetails}
          allPermission={allPermission}
        />
      </div>
    </>
  );
};

export default Refund;
