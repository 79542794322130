import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import { LoadingButton, MoneyBag } from "../../assets/svg/SVG";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LoadingIconGray from "../Common/LoadingGray";
import AccessibleOutlinedIcon from "@mui/icons-material/AccessibleOutlined";
import { toast } from "react-toastify";

const RefundForm = ({
  isOpen,
  toggleDrawer,
  handleSubmit,
  setRefundStatus,
  comment,
  setComment,
  refundLoading,
  refundDetails,
  refundDetailsLoading,
  activeTab,
  setActiveTab,
  paymentDetails,
  allPermission,
}) => {
  const [token, setToken] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const refundCallback = async (updateId) => {
    setUpdateLoading(true);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/refunds/action-callback/${updateId}`,
        requestOptions
      );
      const data = await res.json();

      if (res?.status !== 422) {
        toast(data?.message);
      } else {
        console.error("Error response:", res.status, data);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      size="900px"
      lockBackgroundScroll={true}
      className="overflow-y-scroll overflow-x-hidden pb-20"
    >
      {refundDetailsLoading ? (
        <div className="flex items-center justify-center h-screen">
          <LoadingIconGray />
        </div>
      ) : (
        <div className="px-12 pt-12">
          <div className="flex">
            <MoneyBag />
            <h3 className="pl-[13px] font-bold pb-12 text-[17px]">
              Refund Details
            </h3>
          </div>
          <div className="pb-8">
            <button
              onClick={(e) => setActiveTab("Refund")}
              className={` ${
                activeTab == "Refund" && "border-[#2C4DFF]"
              } mr-4 font-medium rounded-[5px] border-[1px] text-sm h-[38px] w-[122px] bg-[#EDF3FF]`}
            >
              Refund
            </button>
            <button
              onClick={(e) => setActiveTab("Payments")}
              className={`${
                activeTab == "Payments" && "border-[#2C4DFF]"
              } mr-4  font-medium rounded-[5px] border-[1px] text-sm h-[38px] w-[122px] bg-[#EDF3FF]`}
            >
              Payments
            </button>
          </div>
          {activeTab == "Refund" && (
            <div>
              {refundDetails?.status == 0 && (
                <div>
                  <h3 className="py-6 font-bold text-[15px] text-black">
                    Pending Approval at {refundDetails?.created_at}
                  </h3>
                  <div className="border-[#D9D9D9] border-[1px]  p-[24px] rounded-md">
                    <div className="flex justify-between pb-4 border-b-[1px] border-[#F1F1F1]">
                      <div className="w-1/2">
                        <h4 className="text-[#3C3C3C] font-bold text-sm">
                          From
                        </h4>
                        <p className="text-sm py-4">
                          {refundDetails?.payment?.project?.name}
                        </p>
                      </div>
                      <div className="w-1/2">
                        <h4 className="text-[#3C3C3C] font-bold text-sm">
                          Transaction ID
                        </h4>
                        <p className="text-sm py-4">
                          {refundDetails?.payment?.transaction_id}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between pt-4">
                      <div className="w-1/2">
                        <h4 className="text-[#3C3C3C] font-bold text-sm">
                          Value
                        </h4>
                        <p className="text-sm text-[#057051] font-bold py-4">
                          ${refundDetails?.amount}
                        </p>
                      </div>
                      <div className="w-1/2">
                        <h4 className="text-[#3C3C3C] font-bold text-sm">
                          Reason
                        </h4>
                        <p className="text-sm py-4">
                          {refundDetails?.request_comment}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex bg-[#FAFFF1] p-4 border-[1px] rounded-md mt-6 border-[#D5EBAF]">
                    <InfoOutlinedIcon />
                    <p className="ml-3">
                      Note: Once you approved the payment you can not revert it.
                    </p>
                  </div>
                </div>
              )}

              {refundDetails?.status != 0 && (
                <div>
                  <h3 className="py-6 font-bold text-[15px] text-black">
                    <span>Refund </span>
                    {refundDetails?.status == 1 && "approved at"}
                    {refundDetails?.status == 2 && "Declined at"}
                    <span className="ml-4">{refundDetails?.updated_at}</span>
                  </h3>
                  <div className="border-[#D9D9D9] border-[1px]  p-[24px] rounded-md">
                    <div className="flex justify-between pb-4 border-b-[1px] border-[#F1F1F1]">
                      <div>
                        <h4 className="text-[#3C3C3C] font-bold text-sm">
                          From
                        </h4>
                        <p className="text-sm py-4">
                          {refundDetails?.payment?.project?.name}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-[#3C3C3C] font-bold text-sm">
                          Transaction ID
                        </h4>
                        <p className="text-sm py-4">
                          {refundDetails?.payment?.transaction_id}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between pt-4">
                      <div>
                        <h4 className="text-[#3C3C3C] font-bold text-sm">
                          Value
                        </h4>
                        <p className="text-sm text-[#057051] font-bold py-4">
                          ${refundDetails?.amount}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-[#3C3C3C] font-bold text-sm">
                          Reason
                        </h4>
                        <p className="text-sm py-4">
                          {refundDetails?.request_comment}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" bg-[#FAFFF1] p-4 border-[1px] rounded-md mt-6 border-[#D5EBAF]">
                    <div className="flex">
                      <InfoOutlinedIcon />
                      <p className="ml-3">{refundDetails?.reply_comment}</p>
                    </div>
                    <div className="flex mt-5 justify-end italic">
                      {/* <AccessibleOutlinedIcon /> */}
                      <p className="ml-3">
                        {" "}
                        By {refundDetails?.refunded_by?.name}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {refundDetails?.status == 0 &&
                allPermission?.includes("Refund Action Access") && (
                  <form
                    onSubmit={(e) => handleSubmit(e)}
                    className="text-[#2C3C57]"
                  >
                    <label className="text-sm font-semibold flex pt-5 items-center">
                      Comment
                      <span className="pl-2 pt-1 text-[#FF0000] inline-block">
                        *
                      </span>
                    </label>
                    <textarea
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      className="border-[1px] w-full  py-[10px] px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
                      placeholder="Comment"
                    />

                    <div className="grid grid-cols-2 gap-12">
                      <button
                        onClick={() => setRefundStatus(2)}
                        className={` ${
                          refundLoading == "Decline" &&
                          "cursor-not-allowed pointer-events-none"
                        } flex items-center justify-center w-full text-[#595959]  border-[1px] border-[#aeaeae] text-sm font-semibold  px-6 py-3 rounded   mr-1 mb-1  ease-linear transition-all duration-150`}
                        type="submit"
                      >
                        {refundLoading == "Decline" && <LoadingButton />}{" "}
                        <span className="ml-5">Decline</span>
                      </button>
                      <button
                        onClick={() => setRefundStatus(1)}
                        className={` ${
                          refundLoading == "Approve" &&
                          "cursor-not-allowed pointer-events-none"
                        } flex items-center justify-center bg-black w-full  text-white active:bg-blueGray-600 text-sm font-semibold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150`}
                        type="submit"
                      >
                        {refundLoading == "Approve" && <LoadingButton />}{" "}
                        <span className="ml-5">Approve</span>
                      </button>
                    </div>
                  </form>
                )}

              {refundDetails?.status == 1 &&
                refundDetails?.isFailed != null && (
                  <button
                    className={`${
                      updateLoading == true &&
                      "cursor-not-allowed pointer-events-none"
                    } mt-6 bg-[#070337] text-white cursor-pointer p-2 rounded-md w-[200px]`}
                    onClick={() => refundCallback(refundDetails?.id)}
                  >
                    Update API
                  </button>
                )}
            </div>
          )}
          {activeTab == "Payments" && (
            <div>
              <div className="grid grid-cols-2 gap-6 mb-[19px] pt-[25px]">
                <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                  <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                    Payment Details
                  </h3>
                  <div className="flex justify-between mb-3">
                    <p className="text-sm">Total Amount</p>
                    <p className="text-sm">${paymentDetails?.amount}</p>
                  </div>
                  <div className="flex justify-between mb-3">
                    <p className="text-sm whitespace-nowrap">Transaction ID</p>
                    <p className="text-sm flex">ddf</p>
                  </div>
                  <div className="flex justify-between mb-3">
                    <p className="text-sm">Reference</p>
                    <p className="text-sm">{paymentDetails?.reference}</p>
                  </div>
                  <div className="flex justify-between mb-3">
                    <p className="text-sm">Created at</p>
                    <p className="text-sm">{paymentDetails?.created_at}</p>
                  </div>
                </div>
                <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                  <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                    Customer Details
                  </h3>
                  <div className="flex justify-between mb-3">
                    <p className="text-sm">Name</p>
                    <p className="text-sm">{paymentDetails?.customer?.name}</p>
                  </div>
                  <div className="flex justify-between mb-3">
                    <p className="text-sm">Email</p>
                    <p className="text-sm">{paymentDetails?.customer?.email}</p>
                  </div>
                </div>
              </div>
              {paymentDetails?.customer_card && (
                <div className="mb-[19px] shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                  <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                    Card Details
                  </h3>
                  <div className="grid grid-cols-2 gap-20 mb-6">
                    <div>
                      <div className="flex justify-between mb-3">
                        <p className="text-sm text-[#000000] font-medium">
                          Card type:
                        </p>
                        <p className="text-sm">
                          {paymentDetails?.customer_card?.card_type}
                        </p>
                      </div>
                      <div className="flex justify-between mb-3">
                        <p className="text-sm text-[#000000] font-medium">
                          Card year:
                        </p>
                        <p className="text-sm">
                          {paymentDetails?.customer_card?.card_year}
                        </p>
                      </div>
                      <div className="flex justify-between mb-3">
                        <p className="text-sm text-[#000000] font-medium">
                          Card month:
                        </p>
                        <p className="text-sm">
                          {paymentDetails?.customer_card?.card_month}
                        </p>
                      </div>
                      <div className="flex justify-between mb-3">
                        <p className="text-sm text-[#000000] font-medium">
                          Card last four digit:
                        </p>
                        <p className="text-sm">
                          {paymentDetails?.customer_card?.card_last_four_digit}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between mb-3">
                        <p className="text-sm text-[#000000] font-medium">
                          Gateway Name:
                        </p>
                        <p className="text-sm">
                          {paymentDetails?.payment_gateway?.name}
                        </p>
                      </div>
                      <div className="flex justify-between mb-3">
                        <p className="text-sm text-[#000000] font-medium">
                          Status:
                        </p>
                        <p className="text-sm">
                          {paymentDetails?.customer_card?.status == 1
                            ? "Enable"
                            : "Disable"}
                        </p>
                      </div>
                      <div className="flex justify-between mb-3">
                        <p className="text-sm text-[#000000] font-medium">
                          Created at:
                        </p>
                        <p className="text-sm ">
                          {paymentDetails?.customer_card?.created_at}
                        </p>
                      </div>
                      <div className="flex justify-between mb-3">
                        <p className="text-sm text-[#000000] font-medium">
                          Updated at :
                        </p>
                        <p className="text-sm">
                          {paymentDetails?.customer_card?.updated_at}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {paymentDetails?.refunds?.length > 0 && (
                <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px] mb-[19px]">
                  <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold">
                    Refund Details
                  </h3>
                  <table className="w-full text-left -mt-2 border-collapse">
                    <thead>
                      <tr className=" text-black text-sm  whitespace-nowrap ">
                        <th className="py-[18px]">Refunded By</th>
                        <th className="py-[18px]">Amount</th>
                        <th className="py-[18px]">Comment</th>
                        <th className="py-[18px]">Status</th>
                        <th className="py-[18px]">Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentDetails?.refunds?.map((refund, idx) => (
                        <tr
                          key={idx}
                          className="border-b-[1px]  pb-2 border-[#DBDBDB]"
                        >
                          <td className="py-[10px] text-sm text-[#494949]">
                            {refund?.refunded_by?.name}
                          </td>
                          <td className="py-[10px] text-sm text-[#494949]">
                            ${refund?.amount}
                          </td>
                          <td className="py-[10px] text-sm text-[#494949]">
                            {refund?.reply_comment}
                          </td>
                          <td className="py-[10px] text-sm text-[#494949]">
                            {refund?.status == 0 && "Pending"}
                            {refund?.status == 1 && "Approved"}
                            {refund?.status == 2 && "Declined"}
                          </td>
                          <td className="py-[10px] text-sm ">
                            {refund?.created_at}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="grid grid-cols-2 gap-6 mb-16">
                <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                  <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                    Project
                  </h3>
                  <div className="flex justify-between mb-3">
                    <p className="text-sm">Name</p>
                    <p className="text-sm">{paymentDetails?.project?.name}</p>
                  </div>
                </div>
                <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                  <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                    Payment Gate
                  </h3>
                  <div className="flex justify-between mb-3">
                    <p className="text-sm">Name</p>
                    <p className="text-sm">
                      {paymentDetails?.payment_gateway?.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
};

export default RefundForm;
