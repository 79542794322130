import { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import { LoadingButton, User } from "../../assets/svg/SVG";
import CustomDropDown from "../CustomDropDown/CustomDropDown";

const UserForm = ({
  isEdit,
  drawerName,
  isOpen,
  name,
  email,
  password,
  empId,
  toggleDrawer,
  handleSubmit,
  setName,
  setEmail,
  setPassword,
  setEmpId,
  mobile,
  setMobile,
  roleId,
  setRoleId,
  allRole,
  userLoading,
}) => {
  const [selectedRole, setSelectedRole] = useState({});
  useEffect(() => {
    const staticRole = allRole?.find((role) => role.id === roleId);
    setSelectedRole(
      isEdit ? { value: staticRole?.id, label: staticRole?.name } : {}
    );
  }, [roleId, allRole, isEdit]);

  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      size="553px"
      className="overflow-x-scroll 2xl:overflow-x-hidden"
    >
      <div className="px-12 pt-12">
        <h4 className="flex items-center gap-x-[10px] text-lg font-bold">
          <User /> <span>{drawerName}</span>{" "}
        </h4>
        <hr className="mt-3 mb-[45px] border-[1px] bg-[#E0E0E0]" />
        <form onSubmit={(e) => handleSubmit(e)} className="text-[#2C3C57]">
          <label className="text-sm font-semibold flex items-center">
            Name
            <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
          </label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            className="border-[1px] w-full  py-[10px] px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
            placeholder="Name"
          />
          <label className="text-sm font-semibold flex items-center">
            E-mail Address
            <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
          </label>

          <input
            onChange={(e) => setEmail(e.target.value)}
            disabled={isEdit}
            value={email}
            type="email"
            className="border-[1px] w-full  py-[10px] px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
            placeholder="Email"
          />
          <label className="text-sm font-semibold flex items-center">
            Password
            <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
          </label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            value={password}
            className="border-[1px] w-full  py-[10px] px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
            placeholder="Password"
          />
          <label className="text-sm font-semibold flex items-center">
            Employee ID
          </label>
          <input
            value={empId}
            onChange={(e) => setEmpId(e.target.value)}
            className="border-[1px] w-full  py-[10px] px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
            placeholder="Employee id"
          />
          <label className="text-sm font-semibold flex items-center">
            Mobile Number
          </label>

          <input
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className="border-[1px] w-full  py-[10px] px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
            placeholder="Mobile Number"
          />
          <label className="text-sm font-semibold flex items-center">
            Role
            <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
          </label>
          {isEdit
            ? selectedRole?.value && (
                <CustomDropDown
                  value={selectedRole}
                  onChange={(value) => {
                    setSelectedRole(value);
                    setRoleId(value.value);
                  }}
                  options={allRole?.map((option) => {
                    return { value: option?.id, label: option?.name };
                  })}
                  className="border-[1px] w-full   rounded-[10px] mt-3 mb-[32px] focus:outline-0"
                />
              )
            : allRole && (
                <CustomDropDown
                  onChange={(value) => setRoleId(value.value)}
                  options={allRole?.map((option) => {
                    return { value: option?.id, label: option?.name };
                  })}
                  placeholder="Role ID"
                  className="border-[1px] w-full   rounded-[10px] mt-3 mb-[32px] focus:outline-0"
                />
              )}
          <div className="text-center mt-6">
            <button
              disabled={userLoading}
              className={` ${
                userLoading && "cursor-not-allowed"
              } flex items-center justify-center bg-[#2C4DFF] text-white active:bg-blueGray-600 text-sm font-semibold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
              type="submit"
            >
              {userLoading && <LoadingButton />}
              <span className="ml-5">{drawerName}</span>
            </button>
          </div>
        </form>
      </div>
    </Drawer>
  );
};

export default UserForm;
