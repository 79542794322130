import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Login from "../views/auth/Login";

const Auth = () => {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("auth");
    let token = localStorage.getItem("token");
    if (token?.length > 0) navigate("/admin/dashboard");
  }, []);
  return (
    <>
      <main>
        <section className="relative w-full h-full  min-h-screen">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/auth/login" replace />} />
          </Routes>
        </section>
      </main>
    </>
  );
};

export default Auth;
