import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import { toast } from "react-toastify";
import { LoadingButton } from "../../assets/svg/SVG";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ImportPayment = ({
  token,
  isOpenImportPayment,
  setIsOpenImportPayment,
}) => {
  const [importLoading, setImportLoading] = useState(false);
  const [paymentFile, setPaymentFile] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [effect, setEffect] = useState(false);

  const toggleDrawer = () => {
    setIsOpenImportPayment((prevState) => !prevState);
    setSuccessMsg("");
    setEffect(false);
  };

  const handleImportPayment = async (token, e) => {
    setImportLoading(true);
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("csv_file", paymentFile);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/payments/payment-import`,
        requestOptions
      );
      const result = await res.json();

      if (res?.status !== 422) {
        setSuccessMsg(result?.message);
      }
      if (res?.status === 422) {
        for (var key of Object.keys(result?.data)) {
          toast(`${result?.data[key]}`);
        }
      }
      setImportLoading(false);
    } catch (error) {
      setImportLoading(false);
      console.log("error :", error);
    }
  };

  const handleCopyText = (msg) => {
    navigator.clipboard.writeText(msg);
    setEffect(true);
  };

  return (
    <Drawer
      open={isOpenImportPayment}
      onClose={toggleDrawer}
      direction="right"
      size="600px"
    >
      <div className="px-12 pt-12">
        <h4 className="flex items-center gap-x-[10px] text-lg font-bold">
          Import Payment
        </h4>
        <hr className="mt-3 mb-[45px] border-[1px] bg-[#E0E0E0]" />
        <form
          onSubmit={(e) => handleImportPayment(token, e)}
          className="text-[#2C3C57]"
        >
          <label className="text-sm font-semibold flex items-center">
            Upload Payment
            <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
          </label>
          <input
            type="file"
            onChange={(e) => setPaymentFile(e.target.files[0])}
            className="border-[1px] w-full  py-12  px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
            placeholder="Comment"
          />

          <div className="text-center mt-6">
            <button
              className={`${
                importLoading && "cursor-not-allowed"
              } flex items-center justify-center bg-[#2C4DFF] text-white active:bg-blueGray-600 text-sm font-semibold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
              type="submit"
            >
              {importLoading && <LoadingButton />}
              <span className="ml-5">Import Payment</span>
            </button>
          </div>
        </form>
        {successMsg && (
          <div className="pt-16">
            <p>{successMsg}</p>

            <div className="flex items-center">
              <button
                onClick={(e) => handleCopyText(successMsg)}
                className={` py-1 flex px-1 mt-6 border-gray-500 border-[1px] rounded-md`}
              >
                <span className={`mr-2 `}>Copy Text</span>
                <img src="https://fundednext.fra1.digitaloceanspaces.com/dashboard/copy_icon.svg?auto=format&fit=max&w=32" />
              </button>
              {effect && (
                <CheckCircleIcon
                  sx={{
                    color: "green",
                    marginTop: "20px",
                    marginLeft: "10px",
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default ImportPayment;
