import { useEffect, useState } from "react";

// components
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import Spinner from "../../components/Common/Spinner";
import UserForm from "../../components/User/UserForm";

export default function User({ allPermission }) {
  const [isEdit, setIsEdit] = useState(false);
  const [token, setToken] = useState();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [empId, setEmpId] = useState("");
  const [mobile, setMobile] = useState("");
  const [roleId, setRoleId] = useState();
  const [userList, setUserList] = useState([]);
  const [allRole, setAllRole] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [links, setLinks] = useState([]);
  const [userLoading, setUserLoading] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    if (isEdit) {
      setUserId();
      setName("");
      setEmail("");
      setEmpId("");
      setMobile("");
      setRoleId("");
      setIsEdit(false);
    }
  };

  const handleEdit = (user) => {
    setUserId(user?.id);
    setName(user?.name);
    setEmail(user?.email);
    setEmpId(user?.employee_id);
    setMobile(user?.mobile_number);
    setRoleId(user?.role_id);
    toggleDrawer();
    setIsEdit(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserLoading(true);
    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    empId && formdata.append("employee_id", empId);
    mobile && formdata.append("mobile_number", mobile);
    formdata.append("password", password);
    formdata.append("role_id", roleId);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let res = await fetch(
        isEdit
          ? `${process.env.REACT_APP_BASE_URL}/users/update-user/${userId}`
          : `${process.env.REACT_APP_BASE_URL}/users`,
        requestOptions
      );

      const data = await res.json();

      if (res?.status !== 422) {
        getAllUser(`${process.env.REACT_APP_BASE_URL}/users/`);
        toggleDrawer();
        toast(data?.message);
      }

      if (res?.status === 422) {
        for (var key of Object.keys(data?.data)) {
          toast(`${data?.data[key]}`);
        }
      }
      setUserLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllUser = async (url) => {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    setUserList(result?.data?.users);
    setLinks(result?.data?.users?.links);
  };

  const getRole = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/role-permission/get-roles`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setAllRole(data?.data?.roles));
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    token && getAllUser(`${process.env.REACT_APP_BASE_URL}/users/`);
    token && getRole();
  }, [token]);

  if (userList?.length === 0) return <Spinner />;

  return (
    <>
      <div className="w-full">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
          <div className="rounded-t mb-0  py-3">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className="text-2xl font-medium text-[rgba(0, 0, 0, 0.8)]">
                  User List
                </h3>
              </div>
              <div className="">
                {allPermission.includes("User Create") && (
                  <button
                    onClick={toggleDrawer}
                    className="bg-indigo-500 flex items-center justify-between w-[160px] text-white active:bg-indigo-600  text-base font-semibold  px-4 py-[10px] rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    <AddIcon />
                    <span>Create User</span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="w-full overflow-x-auto mt-2 shadow-[0px_4px_45px_#F3F3F3]">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="mx-8 border-b text-[#0F223C] text-sm font-semibold whitespace-nowrap">
                  <th className="py-[23px] pl-5">Name</th>
                  <th className="py-[23px]">Email</th>
                  <th className="py-[23px]">Mobile</th>
                  <th className="py-[23px]">Role</th>
                  <th className="py-[23px]">
                    {allPermission.includes("User Edit") && "Action"}
                    {allPermission.includes("User Delete") && "Action"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {userList?.data?.map((user, idx) => {
                  return (
                    <tr
                      key={idx}
                      className="border-b  border-[#EDEFF3] whitespace-nowrap text-sm hover:bg-gray-200"
                    >
                      <td className="py-7 pl-5">{user?.name}</td>
                      <td className="py-7">{user?.email}</td>
                      <td className="py-7">
                        {" "}
                        {user?.mobile_number || "No number"}
                      </td>
                      <td className="py-7"> {user?.role?.name}</td>
                      <td className="py-7 pr-5">
                        {allPermission.includes("User Edit") && (
                          <button
                            onClick={(e) => handleEdit(user)}
                            className="text-white bg-[#0D86FF] rounded m-1 px-2 py-1"
                          >
                            Edit
                          </button>
                        )}

                        {allPermission.includes("User Delete") && (
                          <button className="text-white bg-[#E03232] rounded m-1 px-2 py-1">
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex justify-end items-center">
          {links?.map((item, idx) => (
            <button
              key={idx}
              onClick={(e) => getAllUser(item.url)}
              className={`${
                item?.active &&
                " !bg-gradient-to-r from-[#588CFF] to-[#2C4DFF] !text-white"
              } first:rounded-r-none  first:w-auto first:px-2 last:px-2 last:w-auto w-[33px] rounded-md h-[33px] flex justify-center items-center last:rounded-l-none cursor-pointer border-2 bg-[#F1F1F1] py-2 text-[#767676] mx-1 border-[#F1F1F1]`}
              dangerouslySetInnerHTML={{
                __html: `${item.label}`,
              }}
            ></button>
          ))}
        </div>

        <UserForm
          isEdit={isEdit}
          drawerName={isEdit ? "Update User" : "Create User"}
          name={name}
          email={email}
          password={password}
          isOpen={isOpen}
          empId={empId}
          mobile={mobile}
          toggleDrawer={toggleDrawer}
          handleSubmit={handleSubmit}
          setName={setName}
          setEmail={setEmail}
          setPassword={setPassword}
          setEmpId={setEmpId}
          setMobile={setMobile}
          setRoleId={setRoleId}
          roleId={roleId}
          allRole={allRole}
          userLoading={userLoading}
        />
      </div>
    </>
  );
}
