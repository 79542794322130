/*eslint-disable*/
import BackupTableOutlinedIcon from "@mui/icons-material/BackupTableOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Money } from "../../assets/svg/SVG";
import AssessmentIcon from "@mui/icons-material/Assessment";
export default function Sidebar({ sidebarPermissions }) {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const location = useLocation();

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-[#E0EAFF] flex flex-wrap items-center justify-between relative md:w-[218px] z-10">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <MenuIcon />
          </button>
          {/* Brand */}
          <Link
            className="flex items-center justify-center bg-white pb-4  py-6 pr-3 lg:mr-0"
            to="/"
          >
            <img src="/logo.svg" className="h-[35px]" />
          </Link>

          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden  items-center flex-1 rounded   h-[100vh] !m-0 lg:!m-2 " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Payment Hub
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
            </div>

            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col pt-8 list-none !rounded-none">
              <li
                className={`text-gray-700 px-4 mx-2 group group-hover cursor-pointer menu-item my-2 hover:bg-white rounded-md group  transition-all duration-300 ease-linear py-4 ${
                  location.pathname == "/admin/dashboard" ? "active" : ""
                }`}
                onClick={() => {
                  setCollapseShow("hidden");
                }}
              >
                <Link
                  to={`/admin/dashboard`}
                  className="capitalize pl-2 text-base font-medium flex gap-x-2 transition-all duration-300 ease-linear items-center  group-hover:text-[#3f74e0]"
                >
                  <DashboardIcon />
                  <span className="pl-2">Dashboard</span>
                </Link>
              </li>
              {sidebarPermissions.includes("Role View") && (
                <li
                  className={`text-gray-700 px-4 mx-2 group group-hover cursor-pointer menu-item my-2 hover:bg-white rounded-md group  transition-all duration-300 ease-linear py-4 ${
                    location.pathname == "/admin/role" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCollapseShow("hidden");
                  }}
                >
                  <Link
                    to={`/admin/role`}
                    className="capitalize pl-2 text-base font-medium flex gap-x-2 transition-all duration-300 ease-linear items-center  group-hover:text-[#3f74e0]"
                  >
                    <BackupTableOutlinedIcon />
                    <span className="pl-2">Role</span>
                  </Link>
                </li>
              )}
              {sidebarPermissions.includes("User View") && (
                <li
                  className={`text-gray-700 px-4 mx-2 group group-hover cursor-pointer menu-item my-2 hover:bg-white rounded-md group  transition-all duration-300 ease-linear py-4 ${
                    location.pathname == "/admin/user" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCollapseShow("hidden");
                  }}
                >
                  <Link
                    to={`/admin/user`}
                    className="capitalize pl-2 text-base font-medium flex gap-x-2 transition-all duration-300 ease-linear items-center  group-hover:text-[#3f74e0]"
                  >
                    <ManageAccountsOutlinedIcon />
                    <span className="pl-2">User</span>
                  </Link>
                </li>
              )}
              {sidebarPermissions.includes("Customer View") && (
                <li
                  className={`text-gray-700 px-4 mx-2 group group-hover cursor-pointer menu-item my-2 hover:bg-white rounded-md group  transition-all duration-300 ease-linear py-4 ${
                    location.pathname == "/admin/customer" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCollapseShow("hidden");
                  }}
                >
                  <Link
                    to={`/admin/customer`}
                    className="capitalize pl-2 text-base font-medium flex gap-x-2 transition-all duration-300 ease-linear items-center  group-hover:text-[#3f74e0]"
                  >
                    <SupportAgentOutlinedIcon />
                    <span className="pl-2">Customer</span>
                  </Link>
                </li>
              )}
              {sidebarPermissions.includes("Refund Access") && (
                <li
                  className={`text-gray-700 px-4 mx-2 group group-hover cursor-pointer menu-item my-2 hover:bg-white rounded-md group  transition-all duration-300 ease-linear py-4 ${
                    location.pathname == "/admin/refund" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCollapseShow("hidden");
                  }}
                >
                  <Link
                    to={`/admin/refund`}
                    className="capitalize pl-2 text-base font-medium flex gap-x-2 transition-all duration-300 ease-linear items-center  group-hover:text-[#3f74e0]"
                  >
                    <Money />
                    <span className="pl-2">Refund</span>
                  </Link>
                </li>
              )}
              {sidebarPermissions.includes("Payment View") && (
                <li
                  className={`text-gray-700 px-4 mx-2 group group-hover cursor-pointer menu-item my-2 hover:bg-white rounded-md group  transition-all duration-300 ease-linear py-4 ${
                    location.pathname == "/admin/payment" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCollapseShow("hidden");
                  }}
                >
                  <Link
                    to={`/admin/payment`}
                    className="capitalize pl-2 text-base font-medium flex gap-x-2 transition-all duration-300 ease-linear items-center  group-hover:text-[#3f74e0]"
                  >
                    <MonetizationOnOutlinedIcon />
                    <span className="pl-2">Payment</span>
                  </Link>
                </li>
              )}
              {sidebarPermissions.includes("Extra Charge View") && (
                <li
                  className={`text-gray-700 px-4 mx-2 group group-hover cursor-pointer menu-item my-2 hover:bg-white rounded-md group  transition-all duration-300 ease-linear py-4 ${
                    location.pathname == "/admin/extra-charge" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCollapseShow("hidden");
                  }}
                >
                  <Link
                    to={`/admin/extra-charge`}
                    className="capitalize pl-2 text-base font-medium flex gap-x-2 transition-all duration-300 ease-linear items-center  group-hover:text-[#3f74e0]"
                  >
                    <MonetizationOnOutlinedIcon />
                    <span className="pl-2">Extra Charge</span>
                  </Link>
                </li>
              )}
              {sidebarPermissions.includes("Gateway View") && (
                <li
                  className={`text-gray-700 px-4 mx-2 group group-hover cursor-pointer menu-item my-2 hover:bg-white rounded-md group  transition-all duration-300 ease-linear py-4 ${
                    location.pathname == "/admin/payment-gateway"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setCollapseShow("hidden");
                  }}
                >
                  <Link
                    to={`/admin/payment-gateway`}
                    className="capitalize pl-2 text-base font-medium flex gap-x-2 transition-all duration-300 ease-linear items-center  group-hover:text-[#3f74e0]"
                  >
                    <MonetizationOnOutlinedIcon />
                    <span className="pl-2 whitespace-nowrap">Gateway</span>
                  </Link>
                </li>
              )}
              {sidebarPermissions.includes("Project View") && (
                <li
                  className={`text-gray-700 px-4 mx-2 group group-hover cursor-pointer menu-item my-2 hover:bg-white rounded-md group  transition-all duration-300 ease-linear py-4 ${
                    location.pathname == "/admin/project" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCollapseShow("hidden");
                  }}
                >
                  <Link
                    to={`/admin/project`}
                    className="capitalize pl-2 text-base font-medium flex gap-x-2 transition-all duration-300 ease-linear items-center  group-hover:text-[#3f74e0]"
                  >
                    <WebhookOutlinedIcon />
                    <span className="pl-2">Project</span>
                  </Link>
                </li>
              )}
              {sidebarPermissions.includes("Crypto Currency Access") && (
                <li
                  className={`text-gray-700 px-4 mx-2 group group-hover cursor-pointer menu-item my-2 hover:bg-white rounded-md group  transition-all duration-300 ease-linear py-4 ${
                    location.pathname == "/admin/crypto-base" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCollapseShow("hidden");
                  }}
                >
                  <Link
                    to={`/admin/crypto-base`}
                    className="capitalize pl-2 text-base font-medium flex gap-x-2 transition-all duration-300 ease-linear items-center  group-hover:text-[#3f74e0]"
                  >
                    <CurrencyBitcoinIcon />
                    <span className="pl-2">Crypto Currency</span>
                  </Link>
                </li>
              )}
              {sidebarPermissions.includes("Can Download Reports") && (
                <li
                  className={`text-gray-700 px-4 mx-2 group group-hover cursor-pointer menu-item my-2 hover:bg-white rounded-md group  transition-all duration-300 ease-linear py-4 ${
                    location.pathname == "/admin/download-reports"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setCollapseShow("hidden");
                  }}
                >
                  <Link
                    to={`/admin/download-reports`}
                    className="capitalize pl-2 text-base font-medium flex gap-x-2 transition-all duration-300 ease-linear items-center  group-hover:text-[#3f74e0]"
                  >
                    <AssessmentIcon />
                    <span className="pl-2">Report List</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
