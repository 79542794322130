import React, { useEffect, useState } from "react";
import Spinner from "../../components/Common/Spinner";
import { ArrowRight, Filter } from "../../assets/svg/SVG";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const ExtraCharge = ({ allPermission }) => {
  const [token, setToken] = useState();
  const [allCharge, setAllCharge] = useState([]);
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [transactionId, setTransactionId] = useState();
  const [parentTransactionId, setParentTransactionId] = useState();

  const handleReset = () => {
    setTransactionId("");
    setParentTransactionId("");
    getAllCharge(`${process.env.REACT_APP_BASE_URL}/get-extra-charges`, true);
  };

  const getAllCharge = async (URL, isReset = false) => {
    setLoading(true);

    if (isFilter && !isReset) {
      if (parentTransactionId) {
        URL = URL + "parent_transaction_id=" + parentTransactionId + "&";
      }
      if (transactionId) {
        URL = URL + "transaction_id=" + transactionId + "&";
      }
      URL = URL.slice(0, -1);
    }
    try {
      const response = await fetch(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setAllCharge(data?.data?.allCharge?.data);
      setLinks(data?.data?.allCharge?.links);
      setLoading(false);
    } catch (error) {
      console.log("error :", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    token &&
      getAllCharge(
        isFilter
          ? `${process.env.REACT_APP_BASE_URL}/get-extra-charges?`
          : `${process.env.REACT_APP_BASE_URL}/get-extra-charges`
      );
  }, [token]);

  if (loading) return <Spinner />;
  return (
    <>
      <div className="w-full">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
          <div className="rounded-t mb-0  py-3">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <div className="flex justify-between">
                  <h3 className="text-2xl font-medium text-[rgba(0, 0, 0, 0.8)]">
                    Extra Charge List
                  </h3>
                  <div className="flex">
                    <button
                      onClick={() => setIsFilter(!isFilter)}
                      className="border-[#2A7FFE] ml-4 border-[1px] flex items-center  text-[#646464] rounded text-[15px] font-medium hover:scale-105 ease-in-out delay-150 py-[8px] px-[17px]"
                    >
                      <Filter />
                      <span className="mx-[10px] text-lg">Filter</span>
                      <span className={`${isFilter ? "" : "rotate-180"}`}>
                        <ArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isFilter && (
            <div className=" bg-[#F8F9FF] px-8 py-8 rounded-md  mb-10">
              <div className="flex items-center flex-wrap gap-y-4 2xl:gap-y-6">
                <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                  <input
                    value={parentTransactionId}
                    type="text"
                    onChange={(e) => setParentTransactionId(e.target.value)}
                    className="px-3 py-[10px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
                    placeholder="Parent Transaction Id"
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                  <input
                    value={transactionId}
                    type="text"
                    onChange={(e) => setTransactionId(e.target.value)}
                    className="px-3 py-[10px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
                    placeholder="Transaction Id"
                  />
                </div>

                <div className="w-full mx-4 mt-5 flex gap-x-8">
                  <button
                    onClick={(e) =>
                      getAllCharge(
                        `${process.env.REACT_APP_BASE_URL}/get-extra-charges?`
                      )
                    }
                    className="bg-[#2A7FFE] text-white text-sm px-7 py-2 rounded font-normal hover:scale-105 ease-in-out delay-150 flex justify-center items-center"
                  >
                    <SearchIcon />
                    <span className="ml-2 text-lg">Search Result</span>
                  </button>
                  <button
                    onClick={() => handleReset()}
                    className="bg-[#F2F2F2] text-[#3E3E3E] px-4 py-2 rounded text-sm hover:scale-105 ease-in-out delay-150 flex justify-center items-center"
                  >
                    <RestartAltIcon />
                    <span className="ml-2 text-lg">Reset</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="w-full overflow-x-auto mt-2 shadow-[0px_4px_45px_#F3F3F3]">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="mx-8 border-b text-[#0F223C] text-sm font-semibold whitespace-nowrap">
                  <th className="py-[23px]">Parent Transaction Id</th>
                  <th className="py-[23px]">Transaction Id</th>
                  <th className="py-[23px]">Amount</th>
                  <th className="py-[23px]">Remarks</th>
                  <th className="py-[23px]">Created At</th>
                </tr>
              </thead>
              <tbody>
                {allCharge?.map((item, idx) => {
                  return (
                    <tr
                      key={idx}
                      className="border-b  border-[#EDEFF3] whitespace-nowrap text-sm hover:bg-gray-200"
                    >
                      <td className="py-7 pl-5">
                        {item?.parent_transaction_id}
                      </td>
                      <td className="py-7">{item?.transaction_id}</td>
                      <td className="py-7">{item?.amount}</td>
                      <td className="py-7">{item?.remarks}</td>
                      <td className="py-7">{item?.created_at}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex justify-end items-center">
          {links?.map((item, idx) => (
            <button
              key={idx}
              onClick={(e) => getAllCharge(item.url)}
              className={`${
                item?.active &&
                " !bg-gradient-to-r from-[#588CFF] to-[#2C4DFF] !text-white"
              } first:rounded-r-none  first:w-auto first:px-2 last:px-2 last:w-auto w-[33px] rounded-md h-[33px] flex justify-center items-center last:rounded-l-none cursor-pointer border-2 bg-[#F1F1F1] py-2 text-[#767676] mx-1 border-[#F1F1F1]`}
              dangerouslySetInnerHTML={{
                __html: `${item.label}`,
              }}
            ></button>
          ))}
        </div>
      </div>
    </>
  );
};

export default ExtraCharge;
