import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../../../components/Common/Spinner";
import CryptoBaseForm from "../../../components/CryptoBase/CryptoBaseForm";
import CryptoBaseRow from "./CryptoBaseRow";

const CryptoBase = ({ allPermission }) => {
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [crypto, setCrypto] = useState();
  const [cryptoIdx, setCryptoIdx] = useState();
  const [status, setStatus] = useState();
  const [allCryptos, setAllCryptos] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [editId, setEditId] = useState();
  const [links, setLinks] = useState([]);
  const [isEdit, setIsEdit] = useState();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);

    if (isEdit) {
      setEditId();
      setName("");
      setCrypto("");
      setStatus();
      setIsEdit(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("network", crypto);
    formdata.append("status", status);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let res = await fetch(
        isEdit
          ? `${process.env.REACT_APP_BASE_URL}/projects/update-project/${editId}`
          : `${process.env.REACT_APP_BASE_URL}/projects/`,
        requestOptions
      );

      const data = await res.json();

      if (res?.status !== 422) {
        toggleDrawer();
        getAllCryptos(`${process.env.REACT_APP_BASE_URL}/projects`);
        toast(data?.message);
      }
      if (res?.status === 422) {
        toggleDrawer();
        for (var key of Object.keys(data?.data)) {
          toast(`${data?.data[key]}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (idx, crypto) => {
    setEditId(crypto?.id);
    setCrypto(crypto);
    setCryptoIdx(idx);
    toggleDrawer();
    setIsEdit(true);
  };

  const getAllCryptos = async (url) => {
    setLoading(true);
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setAllCryptos(data?.data?.crypto_curriencies?.data);
      setLinks(data?.data?.crypto_curriencies?.links);
      setLoading(false);
    } catch (error) {
      console.log("error :", error);
      setLoading(false);
    }
  };

  const handleAllCryptos = async (_val) => {
    setAllCryptos(_val);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    token &&
      getAllCryptos(`${process.env.REACT_APP_BASE_URL}/crypto-currencies`);
  }, [token]);

  if (loading) return <Spinner />;

  return (
    <>
      <div className="w-full">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
          <div className="rounded-t mb-0  py-3">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className="text-2xl font-medium text-[rgba(0, 0, 0, 0.8)]">
                  Crypto List
                </h3>
              </div>
            </div>
          </div>

          <div className="w-full overflow-x-auto mt-2 shadow-[0px_4px_45px_#F3F3F3]">
            {/* Table */}
            <div className="w-full text-left border-collapse">
              {/* Table Header */}
              <div className="w-full">
                <div className="w-full mx-8 border-b text-[#0F223C] text-sm font-semibold whitespace-nowrap grid grid-cols-[1fr_1fr_1fr_1fr]">
                  <div className="py-[23px] pl-5">Id</div>
                  <div className="py-[23px]">Method Name</div>
                  <div className="py-[23px]">Active/Inactive</div>
                  {/* <th className="py-[23px]">Status</th>
                  <th className="py-[23px]">Token</th> */}
                  <div className="py-[23px]">
                    {allPermission.includes("Project Edit") && "Action"}
                  </div>
                </div>
              </div>
              {/* Table Data */}
              <div className="w-full">
                {allCryptos?.map((crypto, idx) => (
                  <CryptoBaseRow
                    key={crypto.id}
                    idx={idx}
                    allCryptos={allCryptos}
                    crypto={crypto}
                    allPermission={allPermission}
                    handleEdit={handleEdit}
                    handleAllCryptos={handleAllCryptos}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end items-center">
          {links?.map((item) => (
            <button
              onClick={(e) => getAllCryptos(item.url)}
              className={`${
                item?.active &&
                " !bg-gradient-to-r from-[#588CFF] to-[#2C4DFF] !text-white"
              } first:rounded-r-none  first:w-auto first:px-2 last:px-2 last:w-auto w-[33px] rounded-md h-[33px] flex justify-center items-center last:rounded-l-none cursor-pointer border-2 bg-[#F1F1F1] py-2 text-[#767676] mx-1 border-[#F1F1F1]`}
              dangerouslySetInnerHTML={{
                __html: `${item.label}`,
              }}
            ></button>
          ))}
        </div>
        <CryptoBaseForm
          drawerName={isEdit ? "Update Crypto" : "Create Crypto"}
          name={name}
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
          handleSubmit={handleSubmit}
          setName={setName}
          crypto={crypto}
          cryptoIdx={cryptoIdx}
          setStatus={setStatus}
          handleAllCryptos={handleAllCryptos}
          allCryptos={allCryptos}
          allPermission={allPermission}
        />
      </div>
    </>
  );
};

export default CryptoBase;
