import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import { MoneyBag } from "../../assets/svg/SVG";
import LoadingIconGray from "../Common/LoadingGray";

const CustomerDetails = ({
  customerDetailsLoading,
  customerDetails,
  isOpen,
  toggleDrawer,
  activeTab,
  setActiveTab,
}) => {
  const [copied, setCopied] = useState("");

  //!  text copy  function
  const handleTextCopy = (value) => {
    navigator.clipboard.writeText(value);
    setCopied(value);
  };

  const handleTruncat = (text, len) => {
    return text.substring(0, len) + "...";
  };

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        size="1200px"
        lockBackgroundScroll={true}
        className="overflow-y-scroll overflow-x-hidden"
      >
        {customerDetailsLoading ? (
          <div className="flex items-center justify-center h-screen">
            <LoadingIconGray />
          </div>
        ) : (
          <div className="p-[40px]">
            <div className="flex pb-[50px]">
              <MoneyBag />
              <h3 className="pl-[13px] font-bold text-[17px]">
                Customer Details
              </h3>
            </div>
            <div className="pb-8">
              <button
                onClick={(e) => setActiveTab("Basic Info")}
                className={` ${
                  activeTab == "Basic Info" && "border-[#2C4DFF]"
                } mr-4 font-medium rounded-[5px] border-[1px] text-sm h-[38px] w-[122px] bg-[#EDF3FF]`}
              >
                Basic Info
              </button>
              <button
                onClick={(e) => setActiveTab("Payments")}
                className={`${
                  activeTab == "Payments" && "border-[#2C4DFF]"
                } mr-4  font-medium rounded-[5px] border-[1px] text-sm h-[38px] w-[122px] bg-[#EDF3FF]`}
              >
                Payments
              </button>
              <button
                onClick={(e) => setActiveTab("Cards")}
                className={`${
                  activeTab == "Cards" && "border-[#2C4DFF]"
                } mr-4  font-medium rounded-[5px] border-[1px] text-sm h-[38px] w-[122px] bg-[#EDF3FF]`}
              >
                Cards
              </button>
            </div>

            {activeTab == "Basic Info" && (
              <div className="">
                <div className="grid grid-cols-2 gap-6 pt-6">
                  <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                    <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                      Customer Basic Info
                    </h3>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">Name</p>
                      <p className="text-sm">
                        {customerDetails?.customer?.name}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Email
                      </p>
                      <p className="text-sm">
                        {customerDetails?.customer?.email}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Created On
                      </p>
                      <p className="text-sm">
                        {customerDetails?.customer?.created_at}
                      </p>
                    </div>
                  </div>
                  <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                    <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                      Customer Finance Info
                    </h3>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Total Paid Amount
                      </p>
                      <p className="text-sm">
                        ${customerDetails?.totalAmountPaid}
                      </p>
                    </div>
                    <div className="flex justify-between mb-3">
                      <p className="text-sm text-[#000000] font-medium">
                        Total Refunded Amount
                      </p>
                      <p className="text-sm">
                        ${customerDetails?.totalRefundPaid}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab == "Payments" && (
              <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px] mb-[19px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold">
                  Payment
                </h3>
                <table className="w-full text-left -mt-2 border-collapse">
                  <thead>
                    <tr className=" text-black text-sm  whitespace-nowrap border-b-[1px] pb-2 border-[#DBDBDB]">
                      <th className="py-[18px]">Transaction ID</th>
                      <th className="py-[18px]">Paid Amount</th>
                      <th className="py-[18px]">Refund Amount</th>
                      <th className="py-[18px]">Reference</th>
                      <th className="py-[18px]">Project</th>
                      <th className="py-[18px]">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerDetails?.customer?.payments?.map((payment) => (
                      <tr className="border-b-[1px] pb-2 border-[#DBDBDB]">
                        <td className="py-[10px] flex items-center text-sm text-[#494949]">
                          <span
                            className={`truncate  ${
                              copied === payment?.transaction_id &&
                              "text-blue-500"
                            }`}
                          >
                            {payment?.transaction_id?.length > 20
                              ? handleTruncat(payment?.transaction_id, 20)
                              : payment?.transaction_id}
                          </span>
                          <span className="pl-3">
                            <img
                              src="https://fundednext.fra1.digitaloceanspaces.com/dashboard/copy_icon.svg?auto=format&fit=max&w=32/copy_icon.svg"
                              alt="image"
                              onClick={() =>
                                handleTextCopy(payment?.transaction_id)
                              }
                              className="cursor-pointer h-[18px] w-[18px]"
                            />
                          </span>
                        </td>
                        <td className="py-[10px] text-sm text-[#494949]">
                          ${payment?.amount}
                        </td>
                        <td className="py-[10px] text-sm text-[#494949]">$0</td>
                        <td className="py-[10px] text-sm text-[#494949]">
                          {payment?.reference}
                        </td>
                        <td className="py-[10px] text-sm text-[#494949]">
                          {payment?.project?.name}
                        </td>
                        <td className="py-[10px] text-sm text-[#494949]">
                          {payment?.created_at}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {activeTab == "Cards" && (
              <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px] mb-[19px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold">
                  Customer Card
                </h3>
                <table className="w-full text-left -mt-2 border-collapse">
                  <thead>
                    <tr className=" text-black text-sm  whitespace-nowrap ">
                      <th className="py-[18px]">Card Type</th>
                      <th className="py-[18px]">Exp. Year</th>
                      <th className="py-[18px]">Exp. Month</th>
                      <th className="py-[18px]">Last 4 digit</th>
                      <th className="py-[18px]">Created at</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerDetails?.customer?.customer_cards?.map((card) => (
                      <tr className="border-b-[1px]  pb-2 border-[#DBDBDB]">
                        <td className="py-[10px] text-sm text-[#494949]">
                          {card?.card_type}
                        </td>
                        <td className="py-[10px] text-sm text-[#494949]">
                          {card?.card_year}
                        </td>
                        <td className="py-[10px] text-sm text-[#494949]">
                          {card?.card_month}
                        </td>
                        <td className="py-[10px] text-sm ">
                          {card?.card_last_four_digit}
                        </td>
                        <td className="py-[10px] text-sm ">
                          {card?.created_at}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default CustomerDetails;
