/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { Router } from "react-router-dom";
import LoadingIconGray from "../../components/Common/LoadingGray";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";

const DownloadReports = ({ allPermission }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [module, setModule] = useState(1);
  const [project, setProject] = useState({ value: 1, label: "FundedNext" });
  const [allDropdown, setAllDropdown] = useState();
  const [links, setLinks] = useState([]);

  const getAllDropdown = async (token) => {
    try {
      let token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/payment/drop-down-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      setAllDropdown(result?.data);
    } catch (error) {
      console.log("error :", error);
    }
  };

  const fetchReportData = async (URL) => {
    try {
      setIsLoading(true);
      let token = localStorage.getItem("token");

      if (project?.value) {
        URL = URL + "&project_id=" + project.value + "&";
      }

      if (module) {
        URL = URL + "module_id=" + module + "&";
      }

      URL = URL + "limit=" + 20;

      const response = await fetch(URL, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the Authorization header
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        setData(responseData.data.data); // Set only the array of data from the response
        let pgLinks = responseData?.data?.links;
        let cp = responseData?.data?.current_page;

        let newLinks = [
          pgLinks[0],
          pgLinks[cp],
          pgLinks[cp + 1],
          pgLinks[pgLinks?.length - 1],
        ];
        if (cp + 2 === pgLinks?.length) {
          newLinks?.pop();
        }

        setLinks(newLinks);
        setIsLoading(false);
      } else {
        console.error("Failed to fetch data");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllDropdown();
    fetchReportData(`${process.env.REACT_APP_BASE_URL}/reports?page=1`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchReportData();
  }, [project]);

  return (
    <div>
      <div className="w-full  pb-24 mt-2">
        <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 mb-4">
          <CustomDropDown
            value={project}
            onChange={(value) => {
              setProject(value);
            }}
            options={allDropdown?.data?.projects?.map((option) => {
              return { value: option?.id, label: option?.name };
            })}
            placeholder="Project Id"
            className="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full ease-linear transition-all duration-150 border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
          />
        </div>
        <h3 className="text-2xl font-medium text-[#000000] flex items-center gap-x-5 mb-[20px] mt-[20px]">
          <span>Report List</span>
        </h3>
        <table className="w-full shadow-[0px_4px_45px_#F3F3F3] text-left border-collapse">
          <thead>
            <tr className="mx-8 border-b text-[#0F223C] text-sm font-semibold whitespace-nowrap">
              <th className="py-[23px] pl-5">ID</th>
              <th className="py-[23px] pl-8">Project Name</th>
              <th className="py-[23px] pl-8">Remarks </th>
              <th className="py-[23px] pl-5">File Name</th>
              <th className="py-[23px] pl-5">Status</th>
              <th className="py-[23px] pr-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan="6" className="py-7">
                  <div className="flex justify-center items-center">
                    <LoadingIconGray />
                  </div>
                </td>
              </tr>
            )}
            {!isLoading &&
              data &&
              data.map((item, idx) => (
                <tr
                  key={item.id}
                  className="border-b border-[#EDEFF3]  text-sm  hover:bg-gray-200"
                >
                  <td className="py-7 pl-5">{item.id}</td>
                  <td className="py-7 pl-5">{item.project_id}</td>
                  <td className="py-7 pl-5">{item.remarks}</td>
                  <td className="py-7 pl-5">{item.file_name}</td>
                  <td className="py-7 pl-5 pr-4">{item.status}</td>
                  <td className="py-7 pr-5">
                    <button
                      onClick={() => {
                        if (item.download_url) {
                          window.location.href = item.download_url;
                        }
                      }}
                      // bg-[#F2F2F2]
                      disabled={!item.download_url}
                      className={`${
                        item.download_url
                          ? "bg-green-100 text-green-800 cursor-pointer"
                          : "bg-gray-100 text-gray-500 cursor-not-allowed"
                      } px-2 py-1 
                      rounded text-sm hover:scale-105 ease-in-out delay-150 flex justify-center items-center`}
                    >
                      <DownloadIcon />
                      <span className="ml-2 text-lg">Download</span>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex justify-end items-center mt-4">
          {links?.map((item, idx) => (
            <button
              key={idx}
              onClick={(e) => fetchReportData(item.url)}
              className={`${
                item?.active &&
                " !bg-gradient-to-r from-[#588CFF] to-[#2C4DFF] !text-white"
              } first:rounded-r-none  first:w-auto first:px-2 last:px-2 last:w-auto w-[33px] rounded-md h-[33px] flex justify-center items-center last:rounded-l-none cursor-pointer border-2 bg-[#F1F1F1] py-2 text-[#767676] mx-1 border-[#F1F1F1]`}
              dangerouslySetInnerHTML={{
                __html: `${item.label}`,
              }}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DownloadReports;
