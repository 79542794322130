import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "../../assets/svg/SVG";
import Modal from "react-modal";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [otpCode, setOtpCode] = useState("");
  const [otpSecret, setOtpSecret] = useState("");

  const handleUserLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    var formdata = new FormData();
    formdata.append("email", email);
    formdata.append("password", password);

    var requestOptions = {
      method: "POST",
      body: formdata,
    };

    try {
      let res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/login`,
        requestOptions
      );
      const data = await res.json();

      if (res.status != 401) {
        setLoading(false);

        if (data?.data?.token) {
          localStorage.setItem("token", data?.data?.token);
          navigate("/admin/dashboard");
        }

        if (data?.data?.otp_secret) {
          setOtpSecret(data?.data?.otp_secret);
          setIsLogin(false);
        }
      } else {
        setLoading(false);
        toast(data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const submitOpt = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        otp_code: otpCode,
        otp_secret: otpSecret,
      }),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/verify-otp`,
        requestOptions
      );
      const result = await response.json();
      toast(result?.message);
      if (result?.message == "Successfully Login") {
        console.log("dusek");
        localStorage.setItem("isTwoFactor", true);
        localStorage.setItem("token", result?.data?.token);
        navigate("/admin/dashboard");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div
        className="mx-auto h-[100vh] w-full bg-[#1E293B]"
        style={{
          backgroundImage: `url("/register_bg_2.png")`,
        }}
      >
        <div className="w-full lg:w-4/12 px-4 h-full  justify-center flex items-center mx-auto ">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0 bg-[#E2E8F0]">
            {isLogin ? (
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="py-10">
                  <h3 className="text-3xl text-center font-bold">
                    Payment Hub{" "}
                  </h3>
                </div>
                <form onSubmit={(e) => handleUserLogin(e)}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      disabled={loading}
                      className={` ${
                        loading && "cursor-not-allowed"
                      } flex items-center justify-center bg-[#1E293B] text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
                      type="submit"
                    >
                      {loading && <LoadingButton />}

                      <span className="ml-5">Sign In</span>
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="flex-auto px-4 lg:px-10 py-10">
                <h3 className="text-2xl font-bold flex justify-center items-center">
                  Two-Factor Authentication
                </h3>
                <p className="text-center text-gray-500 py-3">
                  Enter Two-Factor authentication code to verify your identity
                </p>
                <div className="">
                  <input
                    onChange={(e) => setOtpCode(e.target.value)}
                    placeholder="Authentication Code"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                  />
                  <button
                    onClick={submitOpt}
                    className={` w-full mt-4 flex items-center justify-center bg-[#2C4DFF] text-white active:bg-blueGray-600 text-sm font-semibold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150`}
                  >
                    Verify
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
