import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import { toast } from "react-toastify";
import { MoneyBag } from "../../assets/svg/SVG";
import LoadingIconGray from "../Common/LoadingGray";

const PartialPayment = ({
  paymentDetailsLoading,
  paymentDetails,

  isOpen,
  toggleDrawer,
  handleDataUpdate,
}) => {
  const [copied, setCopied] = useState("");
  const [amount, setAmount] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [isLoading, setIsloading] = useState(false);
  //!  text copy  function
  const handleTextCopy = (value) => {
    navigator.clipboard.writeText(value);
    setCopied(value);
  };

  const handleTruncat = (text, len) => {
    return text.substring(0, len) + "...";
  };

  const handleAddPartialPayment = async () => {
    if (amount === 0 || remarks === "")
      return toast("Please fill all the fields");
    try {
      setIsloading(true);
      let token = localStorage.getItem("token");
      const body = {
        parent_payment_id: paymentDetails?.id,
        amount: amount,
        remarks: remarks,
      };
      const url = process.env.REACT_APP_BASE_URL + "/partial-payments/";
      const result = await fetch(url, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },

        body: JSON.stringify(body),
      });
      const response = await result.json();
      toast(response?.message);
      setAmount(0);
      setRemarks("");
      setIsloading(false);
      handleDataUpdate();
      toggleDrawer();
    } catch (error) {
      toast(error?.message);
      setIsloading(false);
    }
  };
  return (
    <>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        size="900px"
        className="p-[49px] !overflow-y-scroll"
      >
        {paymentDetailsLoading ? (
          <div className="flex items-center justify-center h-screen">
            <LoadingIconGray />
          </div>
        ) : (
          <div>
            <div className="flex">
              <MoneyBag />
              <h3 className="pl-[13px] font-bold text-[17px]">All Details</h3>
            </div>
            <div className="grid grid-cols-2 gap-6 mb-[19px] pt-[25px]">
              <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                  Payment Details
                </h3>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Total Amount</p>
                  <p className="text-sm">${paymentDetails?.amount}</p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="text-sm whitespace-nowrap">Transaction ID</p>
                  <p className="text-sm flex">
                    <span
                      className={`truncate w-full ${
                        copied === paymentDetails?.transaction_id &&
                        "text-blue-500"
                      }`}
                    >
                      {paymentDetails?.transaction_id?.length > 20
                        ? handleTruncat(paymentDetails?.transaction_id, 20)
                        : paymentDetails?.transaction_id}
                    </span>
                    <span className="pl-5">
                      <img
                        src="https://fundednext.fra1.digitaloceanspaces.com/dashboard/copy_icon.svg?auto=format&fit=max&w=32/copy_icon.svg"
                        alt="copy_icon"
                        onClick={() =>
                          handleTextCopy(paymentDetails?.transaction_id)
                        }
                        className="cursor-pointer"
                      />
                    </span>
                  </p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Reference</p>
                  <p className="text-sm">{paymentDetails?.reference}</p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Created at</p>
                  <p className="text-sm">{paymentDetails?.created_at}</p>
                </div>
              </div>
              <div className="shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
                <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                  Customer Details
                </h3>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Name</p>
                  <p className="text-sm">{paymentDetails?.customer?.name}</p>
                </div>
                <div className="flex justify-between mb-3">
                  <p className="text-sm">Email</p>
                  <p className="text-sm">{paymentDetails?.customer?.email}</p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-6  mb-[19px] shadow-[0px_4px_45px_#EFEFEF] p-[24px]">
              <h3 className="text-[#3C3C3C] border-b-[1px] pb-2 border-[#DBDBDB] font-bold mb-3">
                Adjust Payment
              </h3>
              <div className="grid grid-cols-1 gap-6 ">
                <div className="w-full">
                  <input
                    value={amount || "Amount"}
                    type="number"
                    onChange={(e) => setAmount(parseFloat(e.target.value))}
                    className="px-3 py-[10px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] amount focus:outline-[#635bff] placeholder-[#0F223C]"
                    placeholder="Amount"
                  />
                </div>
                <div className="w-full">
                  <textarea
                    value={remarks}
                    type="text"
                    onChange={(e) => setRemarks(e.target.value)}
                    className="px-3 py-[10px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] amount focus:outline-[#635bff] placeholder-[#0F223C]"
                    placeholder="Remarks"
                    rows={3}
                  />
                </div>
                <div className="w-full">
                  {isLoading ? (
                    <div className="w-[200px] ">
                      <LoadingIconGray className="w-[50px] h-[50px]  " />
                    </div>
                  ) : (
                    <button
                      onClick={handleAddPartialPayment}
                      className="relative group overflow-hidden px-8 py-2 min-w-[200px] rounded-md bg-[#2a7ffee6]
                      before:absolute 
                      before:inset-0 
                      before:bg-[#2A7FFE] 
                      before:scale-y-[0.1] 
                      before:origin-bottom
                      before:transition
                      before:duration-300
                      hover:before:scale-y-100
                      disabled:bg-[#2a7ffee6] disabled:cursor-not-allowed disabled:text-[#fff] disabled:hover:before:scale-y-0
                      "
                      disabled={amount === 0 || remarks === ""}
                    >
                      <span class="relative  text-base text-white">Adjust</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default PartialPayment;
