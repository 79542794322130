import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";

// components
import { toast } from "react-toastify";
import Spinner from "../../components/Common/Spinner";
import RoleForm from "../../components/Role/RoleForm";

export default function Role({ allPermission }) {
  const [isEdit, setIsEdit] = useState(false);
  const [token, setToken] = useState();
  const [name, setName] = useState("");
  const [permissioList, setPermissionList] = useState([]);
  const [permissionId, setPermissionId] = useState([]);
  const [allRole, setAllRole] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [edidId, setEditId] = useState();
  const [roleLoading, setRoleLoading] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    if (isEdit) {
      setName("");
      setPermissionId([]);
      setIsEdit(false);
    }
  };

  const handleEdit = (role) => {
    setEditId(role?.id);
    setName(role?.name);
    let editPermission = role?.permissions.map((option) => {
      return { value: option.id, label: option.name };
    });
    setPermissionId(editPermission);
    toggleDrawer();
    setIsEdit(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRoleLoading(true);
    const allSelectedPermission = permissionId.map((item) => item.value);

    const reqBody = JSON.stringify({
      name: name,
      permissions_id: allSelectedPermission,
    });

    var requestOptions = {
      method: "POST",
      body: reqBody,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      let res = await fetch(
        isEdit
          ? `${process.env.REACT_APP_BASE_URL}/role-permission/update-roles/${edidId}`
          : `${process.env.REACT_APP_BASE_URL}/role-permission/store-roles`,
        requestOptions
      );

      const data = await res.json();

      if (res?.status !== 422) {
        toggleDrawer();
        getRole();
        toast(data?.message);
      }
      if (res?.status === 422) {
        for (var key of Object.keys(data?.data)) {
          toast(`${data?.data[key]}`);
        }
      }

      setRoleLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getPermission = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/role-permission/get-permissions`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setPermissionList(data?.data?.permissions));
  };

  const getRole = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/role-permission/get-roles`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setAllRole(data?.data?.roles));
  };

  const updatedPermission = permissioList.map((option) => {
    return { value: option.id, label: option.name };
  });

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    token && getPermission();
    token && getRole();
  }, [token]);

  if (allRole.length === 0) return <Spinner />;

  return (
    <>
      <div className="w-full">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
          <div className="rounded-t mb-0  py-3">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className="text-2xl font-medium text-[#000000] flex items-center gap-x-5">
                  <span>Role List</span>
                </h3>
              </div>
              <div className="">
                {allPermission.includes("Role Create") && (
                  <button
                    onClick={toggleDrawer}
                    className="bg-indigo-500 flex items-center justify-between w-[160px] text-white active:bg-indigo-600  text-base font-semibold  px-4 py-[10px] rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    <AddIcon />
                    <span> Create Role</span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="w-full overflow-x-auto mt-2 shadow-[0px_4px_45px_#F3F3F3]">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="mx-8 border-b text-[#0F223C] text-sm font-semibold whitespace-nowrap">
                  <th className="py-[23px] pl-5">Role ID</th>
                  <th className="py-[23px]">Role Name</th>
                  <th className="py-[23px]">Permission LIst</th>
                  <th className="py-[23px]">
                    {allPermission.includes("Role Edit") && "Action"}
                    {allPermission.includes("Role Delete") && "Action"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {allRole?.map((role, idx) => {
                  return (
                    <tr
                      key={idx}
                      className="border-b  border-[#EDEFF3] whitespace-nowrap text-sm hover:bg-gray-200"
                    >
                      <td className="py-7 pl-5">{role?.id}</td>
                      <td className="py-7">{role?.name}</td>
                      <td className="py-7 flex flex-wrap">
                        {role?.permissions?.map((item, idx) => {
                          return (
                            <p
                              className="inline text-white bg-[#3399FF] rounded m-[2px]  p-[4px]"
                              key={idx}
                            >
                              {item?.name}
                            </p>
                          );
                        })}
                      </td>
                      <td className="py-7 pr-5">
                        {allPermission.includes("Role Edit") && (
                          <button
                            onClick={(e) => handleEdit(role)}
                            className="text-white bg-[#0D86FF] rounded m-1 px-2 py-1"
                          >
                            Edit
                          </button>
                        )}

                        {allPermission.includes("Role Delete") && (
                          <button className="text-white bg-[#E03232] rounded m-1 px-2 py-1">
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <RoleForm
          roleLoading={roleLoading}
          name={name}
          drawerName={isEdit ? "Update Role" : "Create Role"}
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
          handleSubmit={handleSubmit}
          setName={setName}
          updatedPermission={updatedPermission}
          permissionId={permissionId}
          setPermissionId={setPermissionId}
        />
      </div>
    </>
  );
}
