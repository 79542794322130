import { useEffect, useState } from "react";
import Spinner from "../../components/Common/Spinner";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import CustomerDetails from "../../components/Customer/CustomerDetails";
import LoadingIconGray from "../../components/Common/LoadingGray";

const Customer = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState();
  const [customerList, setCustomerList] = useState([]);
  const [links, setLinks] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [showAction, setShowAction] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [customerDetails, setCustomerDetails] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [customerDetailsLoading, setCustomerDetailsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Basic Info");

  const getCustomerDetails = async (token, id) => {
    setCustomerDetailsLoading(true);
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/customer/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    let result = await res.json();
    if (result) {
      setCustomerDetails(result?.data);
    }
    setCustomerDetailsLoading(false);
  };

  const getAllCustomer = async (url) => {
    setLoading(true);
    let regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

    if (isSearch) {
      if (searchValue?.trim().match(regex)) {
        url = url + "email=" + searchValue + "&";
      } else {
        url = url + "name=" + searchValue + "&";
      }

      url = url.slice(0, -1);
    }

    let response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    setCustomerList(result?.data?.customers?.data);
    // cp = current page
    // pgLinks= all pagination links

    let pgLinks = result?.data?.customers?.links;
    let cp = result?.data?.customers?.current_page;

    let newLinks = [
      pgLinks[0],
      pgLinks[cp],
      pgLinks[cp + 1],
      pgLinks[pgLinks?.length - 1],
    ];
    if (cp + 2 === pgLinks?.length) {
      newLinks?.pop();
    }
    setLinks(newLinks);
    setLoading(false);
  };

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    setActiveTab("Basic Info");
  };

  const handleAction = (id) => {
    setShowAction(!showAction);
    setCustomerId(id);
    token && getCustomerDetails(token, id);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    token &&
      getAllCustomer(
        isSearch
          ? `${process.env.REACT_APP_BASE_URL}/customers-list?`
          : `${process.env.REACT_APP_BASE_URL}/customers-list`
      );
  }, [token, isSearch]);

  return (
    <>
      <div className="w-full">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
          <div className="rounded-t mb-0  py-3">
            <div className="flex justify-between items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className="text-2xl  font-medium text-[rgba(0, 0, 0, 0.8)]">
                  Customer List
                </h3>
              </div>
              <div className="flex items-center">
                <input
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search by name or email"
                  className="border-[1px] w-full py-[10px] px-7 h-[47px] rounded-[10px] focus:outline-[1px] outline-[#2C4DFF]"
                />
                <div className="flex">
                  <button
                    onClick={(e) => setIsSearch(true)}
                    className={`ml-8 w-[150px] border-[1px] border-[#2C4DFF] h-[46px] py-[10px] px-7 flex items-center justify-center bg-[#2C4DFF] text-white active:bg-blueGray-600 text-sm font-semibold  rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150`}
                    type="submit"
                  >
                    Search
                  </button>
                  <button
                    onClick={(e) => {
                      setIsSearch(false);
                      setSearchValue("");
                    }}
                    className={`ml-8 w-[70px]  font-bold  h-[46px] py-[10px] px-12 flex items-center justify-center bg-[#EDF2FD] text-[#2C4DFF] active:bg-blueGray-600 text-sm  rounded shadow outline-none focus:outline-none ease-linear transition-all duration-150`}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <LoadingIconGray />
          ) : (
            <div className="w-full  mt-2 shadow-[0px_4px_45px_#F3F3F3]">
              <table className="w-full text-left border-collapse">
                <thead>
                  <tr className="mx-8 border-b text-[#0F223C] text-sm font-semibold whitespace-nowrap">
                    <th className="py-[23px] pl-5">Customer Name</th>
                    <th className="py-[23px]">Customer Email</th>
                    <th className="py-[23px]">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {customerList?.map((customer, idx) => {
                    return (
                      <tr
                        key={idx}
                        className="border-b  border-[#EDEFF3] whitespace-nowrap text-sm hover:bg-gray-200"
                      >
                        <td className="py-7 pl-7">{customer?.name}</td>
                        <td className="py-7">{customer?.email}</td>
                        <td className="py-7">
                          <div className="flex items-center">
                            <div className="ml-2 flex">
                              <div className="flex">
                                <div className="relative">
                                  <button
                                    onClick={() => handleAction(customer?.id)}
                                    type="button"
                                    className={` ${
                                      customerId == customer?.id &&
                                      showAction &&
                                      "text-gray-700 bg-gray-50"
                                    } inline-flex px-2 py-1 border-[1px] border-[#f0f0f0] rounded-md items-center justify-center h-full text-gray-600  hover:text-gray-700  hover:bg-gray-50`}
                                  >
                                    <LogoutIcon sx={{ fontSize: "20px" }} />
                                    <ArrowDropDownIcon />
                                  </button>

                                  <div
                                    className={`${
                                      customerId == customer?.id && showAction
                                        ? "visible"
                                        : "invisible"
                                    }  absolute left-[-15px] z-10 w-32  mt-4 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg`}
                                  >
                                    <div className="p-2">
                                      <button
                                        onClick={toggleDrawer}
                                        className="block w-full cursor-pointer px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                                      >
                                        Details
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="flex justify-end items-center">
          {links?.map((item, idx) => (
            <button
              key={idx}
              onClick={(e) => getAllCustomer(item.url)}
              className={`${
                item?.active &&
                " !bg-gradient-to-r from-[#588CFF] to-[#2C4DFF] !text-white"
              } first:rounded-r-none  first:w-auto first:px-2 last:px-2 last:w-auto w-[33px] rounded-md h-[33px] flex justify-center items-center last:rounded-l-none cursor-pointer border-2 bg-[#F1F1F1] py-2 text-[#767676] mx-1 border-[#F1F1F1]`}
              dangerouslySetInnerHTML={{
                __html: `${item.label}`,
              }}
            ></button>
          ))}
        </div>
        <CustomerDetails
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          customerDetailsLoading={customerDetailsLoading}
          id={customerId}
          isOpen={isOpen}
          customerDetails={customerDetails}
          toggleDrawer={toggleDrawer}
        />
      </div>
    </>
  );
};

export default Customer;
