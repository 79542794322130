import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../components/Common/Spinner";
import ChangePassworod from "../components/User/ChangePassowrd";

const AdminNavbar = ({ userInfo }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState();
  const [isProfileShow, setIsProfileShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [passwordLoading, setPasswordLoading] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleLogout = async (token) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/logout`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();

      if (result) {
        setLoading(false);
        toast(result.message);
        localStorage.removeItem("token");
        navigate("/auth/login");
      }
    } catch (error) {
      setLoading(false);
      console.log("error :", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPasswordLoading(true);
    var requestOptions = {
      method: "POST",
      body: JSON.stringify({
        current_password: password,
        new_password: newPassword,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/users/change-password/${userInfo?.id}`,
        requestOptions
      );
      const result = await response.json();
      if (result) {
        toast(result.message);
        setPasswordLoading(false);
      }
    } catch (error) {
      setPasswordLoading(false);
      console.log("error :", error);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  if (loading) return <Spinner />;

  return (
    <>
      <div className="h-[77px] px-5  lg:px-12 flex  items-center justify-end border-b-[1px] border-[#EDEFF3]">
        <div className="flex items-center">
          <img src="/user.svg" alt="" />
          <div className="ml-2 flex">
            <div className="flex">
              <a href="#" className="px-4 py-2 text-[#818181] text-[17px]">
                {userInfo?.name}
              </a>

              <div className="relative">
                <button
                  onClick={(e) => setIsProfileShow(!isProfileShow)}
                  type="button"
                  className="inline-flex items-center justify-center h-full px-2 text-gray-600 border-l border-gray-100 hover:text-gray-700 rounded-r-md hover:bg-gray-50"
                >
                  <ArrowDropDownIcon />
                </button>

                <div
                  className={`${
                    isProfileShow ? "visible" : "invisible"
                  }  absolute right-0 z-10 w-48  mt-4 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg`}
                >
                  <div className="p-2">
                    <p className="block px-4 py-2 font-medium text-base text-gray-700">
                      {userInfo?.email}
                    </p>
                    <button
                      onClick={(e) => toggleDrawer()}
                      className="flex w-full px-4 border-[1px] border-l-0 border-r-0 py-2 text-base text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                    >
                      <AccountCircleIcon />
                      <span className="pl-3">Profile</span>
                    </button>
                    <button
                      onClick={(e) => handleLogout(token)}
                      className="flex w-full px-4 py-2 text-base text-gray-500  hover:bg-gray-50 hover:text-gray-700"
                    >
                      <MeetingRoomIcon />
                      <span className="pl-3">Logout</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangePassworod
        toggleDrawer={toggleDrawer}
        isOpen={isOpen}
        handleSubmit={handleSubmit}
        password={password}
        setPassword={setPassword}
        passwordLoading={passwordLoading}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
      />
    </>
  );
};

export default AdminNavbar;
