import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";

const CryptoBaseRow = ({
  idx,
  crypto,
  allPermission,
  handleEdit,
  allCryptos,
  handleAllCryptos,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [token, setToken] = useState();
  const [checked, setChecked] = useState(null);

  const handleCrypto = async (_id, _status, _for) => {
    if (_for === "crypto" && token) {
      setDisabled(true);

      const url = `${process.env.REACT_APP_BASE_URL}/crypto-currencies/${_id}`;

      const allCryptosCopy = [...allCryptos];
      const found = await allCryptos.find((crypto) => crypto.id === _id);
      allCryptosCopy[idx].status = _status;
      if (
        allCryptosCopy[idx].networks &&
        allCryptosCopy[idx].networks.length > 0
      ) {
        const updatedNetworks = allCryptosCopy[idx].networks.map((item) => ({
          ...item,
          status: _status,
        }));
        allCryptosCopy[idx].networks = updatedNetworks;
      }

      try {
        const reqObj = { status: _status, networks: found.networks };
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(reqObj),
        });
        const data = await response.json();
        handleAllCryptos(allCryptosCopy);
        setDisabled(false);
      } catch (error) {
        console.log("error :", error);
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    if (!allPermission.includes("Crypto Currency Update Access"))
      setDisabled(true);
    setChecked(crypto.status === "ACTIVE" ? true : false);
  }, [crypto.status, allPermission]);
  
  return (
    <div className="w-full border-b  border-[#EDEFF3] whitespace-nowrap text-sm hover:bg-gray-200 grid grid-cols-[1fr_1fr_1fr_1fr] mx-8">
      <div className="py-7 pl-5">{crypto?.id}</div>
      <div className="py-7"> {crypto?.name}</div>
      {/* <td className="py-7"> {crypto?.status}</td>
                        <td className="py-7"> {crypto?.token}</td> */}
      <div className="py-7">
        {checked !== null && (
          <Toggle
            id="currency-status"
            checked={checked}
            onChange={() =>
              handleCrypto(
                crypto?.id,
                crypto.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                "crypto"
              )
            }
            disabled={disabled}
          />
        )}
      </div>
      <div className="py-7 pr-5">
        {allPermission.includes("Project Edit") && (
          <button
            onClick={(e) =>
              crypto.networks &&
              crypto?.networks?.length > 0 &&
              handleEdit(idx, crypto)
            }
            className={`text-white rounded m-1 px-2 py-1 ${
              crypto.networks && crypto?.networks?.length > 0
                ? "bg-[#0D86FF]"
                : "bg-gray-400 cursor-not-allowed"
            }`}
          >
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default CryptoBaseRow;
