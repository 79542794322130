import DownloadIcon from "@mui/icons-material/Download";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
// import { ArrowRight, Dollars, Filter } from "assets/svg/SVG";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import CircularProgress from "@mui/material/CircularProgress";
import { addDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ArrowRight, Dollars, Filter } from "../../assets/svg/SVG";
import LoadingIconGray from "../../components/Common/LoadingGray";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import ImportPayment from "../../components/Payment/ImportPayment";
import PartialPayment from "../../components/Payment/PartialPayment";
import PaymentDetails from "../../components/Payment/PaymentDetails";

const Payment = ({ allPermission }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [links, setLinks] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [allData, setAllData] = useState();
  const [reference, setReference] = useState();
  const [project, setProject] = useState();
  const [gateway, setGateway] = useState();
  const [email, setEmail] = useState();
  const [transactionId, setTransactionId] = useState();
  const [allDropdown, setAllDropdown] = useState();
  const [showAction, setShowAction] = useState(false);
  const [paymentId, setPaymentId] = useState();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState();
  const [isOpenImportPayment, setIsOpenImportPayment] = useState(false);
  const [copied, setCopied] = useState("");
  const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDownloadGenerate, setShowDownloadGenerate] = useState(false);
  const [isReportLoading, setIsreportLoading] = useState(false);
  const [isPaymentDrawerOpen, setIsPaymentDrawerOpen] = useState(false);

  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  //!  text copy  function
  const handleTextCopy = (value) => {
    navigator.clipboard.writeText(value);
    setCopied(value);
  };

  const toggleDetailsDrawer = () => {
    setIsDetailsOpen((prevState) => !prevState);
  };

  const getAllPayment = async (URL, isReset = false) => {
    setLoading(true);

    if (isFilter && !isReset) {
      if (project?.value) {
        URL = URL + "project_id=" + project.value + "&";
      }

      if (gateway?.value) {
        URL = URL + "payment_gateway_id=" + gateway.value + "&";
      }

      if (transactionId) {
        URL = URL + "transaction_id=" + transactionId + "&";
      }

      if (reference) {
        URL = URL + "reference=" + reference + "&";
      }
      if (startDate) {
        URL = URL + "startDate=" + startDate + "&";
      }
      if (endDate) {
        URL = URL + "endDate=" + endDate + "&";
      }
      if (email) {
        URL = URL + "customer_email=" + email + "&";
      }
      URL = URL.slice(0, -1);
    }

    try {
      const response = await fetch(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();

      setAllData(result?.data);
      setPaymentList(result?.data?.payments?.data);

      setShowDownloadGenerate(true);

      // cp = current page
      // pgLinks= all pagination links

      let pgLinks = result?.data?.payments?.links;
      let cp = result?.data?.payments?.current_page;

      let newLinks = [
        pgLinks[0],
        pgLinks[cp],
        pgLinks[cp + 1],
        pgLinks[pgLinks?.length - 1],
      ];
      if (cp + 2 === pgLinks?.length) {
        newLinks?.pop();
      }
      setLinks(newLinks);
      setLoading(false);
    } catch (error) {
      console.log("error :", error);
      setLoading(false);
    }
  };

  const getPaymentDetails = async (token, id) => {
    setPaymentDetailsLoading(true);
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/payments/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    let result = await res.json();
    if (result) {
      setPaymentDetails(result?.data?.payment);
    }
    setPaymentDetailsLoading(false);
  };

  const getAllDropdown = async (token) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/payment/drop-down-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      setAllDropdown(result?.data);
    } catch (error) {
      console.log("error :", error);
    }
  };

  const handleRefund = (id) => {
    setShowAction(!showAction);
    setPaymentId(id);
    token && getPaymentDetails(token, id);
  };

  const handleTruncat = (text, len) => {
    return text.substring(0, len) + "...";
  };

  const handleReset = () => {
    setEmail("");
    setGateway("");
    setProject("");
    setTransactionId("");
    setReference("");
    setStartDate("");
    setEndDate("");
    getAllPayment(`${process.env.REACT_APP_BASE_URL}/payment-list`, true);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    token &&
      getAllPayment(
        isFilter
          ? `${process.env.REACT_APP_BASE_URL}/payment-list?`
          : `${process.env.REACT_APP_BASE_URL}/payment-list`
      );
  }, [token]);

  useEffect(() => {
    token && getAllDropdown(token);
  }, [isFilter]);

  const handleShowDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDateRange = (item) => {
    setDateState([item.selection]);

    const startDateFormatted = format(item.selection.startDate, "yyyy-MM-dd");
    const endDateFormatted = format(item.selection.endDate, "yyyy-MM-dd");

    setStartDate(startDateFormatted);
    setEndDate(endDateFormatted);
  };

  const generateReport = async () => {
    setIsreportLoading(true);
    const token = localStorage.getItem("token");

    const queryParams = new URLSearchParams({
      module_id: "1",
      project_id: project?.value || "",
      payment_gateway_id: gateway?.value || "",
      start_date: startDate || "",
      end_date: startDate || "",
    });

    if (!startDate && !startDate) {
      toast.warning("Please give date range");
      setIsreportLoading(false);
      return;
    }

    if (!project?.value && !gateway?.value) {
      setIsreportLoading(false);
      toast.warning("Missed Project Id or Payment Gateway");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/reports/generate?${queryParams}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const result = await response.json();

      if (response.ok) {
        toast(result?.message);
        setIsreportLoading(false);
      } else {
        toast(result?.message);
        setIsreportLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsreportLoading(false);
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
          <div className="flex justify-between mb-4">
            <h3 className="text-2xl font-medium text-[#000000] flex items-center gap-x-5">
              <img src="/payment.svg" alt="" /> <span>Payment List</span>
              <span className="text-sm -ml-2">
                {allData?.payments?.total && `(${allData?.payments?.total})`}
              </span>
            </h3>
            <div className="flex">
              <button
                onClick={() => setIsOpenImportPayment(!isOpenImportPayment)}
                className="border-[#2A7FFE] border-[1px] flex items-center  text-[#646464] rounded text-[15px] font-medium  ease-in-out delay-150 py-[8px] px-[17px]"
              >
                Import
              </button>
              <button
                onClick={() => setIsFilter(!isFilter)}
                className="border-[#2A7FFE] ml-4 border-[1px] flex items-center  text-[#646464] rounded text-[15px] font-medium hover:scale-105 ease-in-out delay-150 py-[8px] px-[17px]"
              >
                <Filter />
                <span className="mx-[10px] text-lg">Filter</span>
                <span className={`${isFilter ? "" : "rotate-180"}`}>
                  <ArrowRight />
                </span>
              </button>
            </div>
          </div>

          {isFilter && (
            <div className=" bg-[#F8F9FF] px-8 py-8 rounded-md  mb-10">
              <div className="flex items-center flex-wrap gap-y-4 2xl:gap-y-6">
                <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                  <CustomDropDown
                    value={gateway}
                    onChange={(value) => setGateway(value)}
                    options={allDropdown?.data?.paymentGateways?.map(
                      (option) => {
                        return { value: option?.id, label: option?.name };
                      }
                    )}
                    placeholder="Payment Gateway Id"
                    className="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full ease-linear transition-all duration-150 border-[#D7D7D7] border-[1px] focus:outline-[#635bff]"
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                  <CustomDropDown
                    value={project}
                    onChange={(value) => setProject(value)}
                    options={allDropdown?.data?.projects?.map((option) => {
                      return { value: option?.id, label: option?.name };
                    })}
                    placeholder="Project Id"
                    className="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full ease-linear transition-all duration-150 border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                  <input
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="px-3 py-[10px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
                    placeholder="Customer Email"
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                  <input
                    value={transactionId}
                    type="text"
                    onChange={(e) => setTransactionId(e.target.value)}
                    className="px-3 py-[10px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
                    placeholder="Transaction Id"
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                  <input
                    value={reference}
                    type="text"
                    onChange={(e) => setReference(e.target.value)}
                    className="px-3 py-[10px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
                    placeholder="Reference"
                  />
                </div>
                <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                  <button
                    onClick={handleShowDatePicker}
                    className="border-[#2A7FFE]  ml-4 border-[1px] flex items-center text-[#646464] rounded text-[15px] font-medium hover:scale-105 ease-in-out delay-150 py-[8px] px-[17px]"
                  >
                    Show Date Range Picker
                  </button>
                  {showDatePicker && (
                    <div className="absolute top-[230px] left-[50px] 2xl:left-[270px] z-50">
                      <DateRangePicker
                        onChange={(item) => handleDateRange(item)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={dateState}
                        direction="horizontal"
                        preventSnapRefocus={true}
                        calendarFocus="backwards"
                        minDate={addDays(new Date(), -30)}
                      />
                    </div>
                  )}
                </div>
                <div className="w-full mx-4 mt-5 flex gap-x-8">
                  <button
                    onClick={(e) =>
                      getAllPayment(
                        `${process.env.REACT_APP_BASE_URL}/payment-list?`
                      )
                    }
                    className="bg-[#2A7FFE] text-white text-sm px-7 py-2 rounded font-normal hover:scale-105 ease-in-out delay-150 flex justify-center items-center"
                  >
                    <SearchIcon />
                    <span className="ml-2 text-lg">Search Result</span>
                  </button>
                  <button
                    onClick={() => handleReset()}
                    className="bg-[#F2F2F2] text-[#3E3E3E] px-4 py-2 rounded text-sm hover:scale-105 ease-in-out delay-150 flex justify-center items-center"
                  >
                    <RestartAltIcon />
                    <span className="ml-2 text-lg">Reset</span>
                  </button>
                  {showDownloadGenerate && (
                    <button
                      disabled={isReportLoading}
                      onClick={() => generateReport()}
                      className="bg-[#F2F2F2] text-[#3E3E3E] px-4 py-2 rounded text-sm hover:scale-105 ease-in-out delay-150 flex justify-center items-center"
                    >
                      {isReportLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <DownloadIcon />
                      )}
                      <span className="ml-2 text-lg">
                        Add to Download Report list
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

          {!loading ? (
            <>
              <div className="w-full !overflow-y-clip !overflow-x-scroll pb-24 mt-2">
                <table className="w-full shadow-[0px_4px_45px_#F3F3F3] text-left border-collapse">
                  <thead>
                    <tr className="mx-8 border-b text-[#0F223C] text-sm font-semibold whitespace-nowrap">
                      <th className="py-[23px] pl-5">ID</th>
                      <th className="py-[23px] pr-4">Customer Email</th>
                      <th className="py-[23px] pr-4">Reference</th>
                      <th className="py-[23px] pr-4">Gateway</th>

                      <th className="py-[23px] pr-4">Amount</th>
                      <th className="py-[23px] pr-4">Transaction Id</th>
                      <th className="py-[23px] pr-4">Project</th>
                      <th className="py-[23px] pr-4">Gateway</th>
                      <th className="py-[23px]  pr-4">Paid At</th>
                      <th className="py-[23px]">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentList?.map((item, idx) => {
                      return (
                        <tr
                          key={idx}
                          onClick={() => setShowAction(!showAction)}
                          className="border-b border-[#EDEFF3] whitespace-nowrap text-sm  hover:bg-gray-200"
                        >
                          <td className="py-7 pl-5">{idx + 1}</td>
                          <td className="py-7 pr-4">{item?.customer?.email}</td>
                          <td className="py-7 pr-4">{item?.reference}</td>
                          <td className="py-7 pr-4">
                            {" "}
                            {/^partial/.test(item?.transaction_id) ? (
                              <p className="text-[blue]">Partial</p>
                            ) : (
                              <p>Default</p>
                            )}
                          </td>

                          <td className="py-7 pr-4 relative">
                            <span className="tooltip">
                              {item?.has_active_refund != 0 && (
                                <span className="bg-red-600 w-2 h-2 rounded-full inline-block mr-1"></span>
                              )}
                              ${item?.amount}{" "}
                              {item?.has_active_charge != 0 && (
                                <span className="bg-green-600 ml-1 w-2 h-2 rounded-full inline-block"></span>
                              )}
                              {(item?.has_active_charge != 0 ||
                                item?.has_active_refund != 0) && (
                                <span className="tooltip-text absolute z-10 top-16 left-1/2 transform -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-sm rounded-md">
                                  {item?.has_active_refund != 0 &&
                                    "Has Refund "}{" "}
                                  <br />
                                  {item?.has_active_charge != 0 &&
                                    " Has Charged"}
                                </span>
                              )}
                            </span>
                          </td>
                          <td className="py-7 pr-4">
                            <div className="flex items-center py-3 px-5">
                              <span
                                className={`truncate w-full ${
                                  copied === item?.transaction_id &&
                                  "text-blue-500"
                                }`}
                              >
                                {item?.transaction_id?.length > 30
                                  ? handleTruncat(item?.transaction_id, 30)
                                  : item?.transaction_id}
                              </span>
                              <span className="pl-5">
                                <img
                                  src="https://fundednext.fra1.digitaloceanspaces.com/dashboard/copy_icon.svg?auto=format&fit=max&w=32/copy_icon.svg"
                                  alt="image"
                                  onClick={() =>
                                    handleTextCopy(item?.transaction_id)
                                  }
                                  className="cursor-pointer"
                                />
                              </span>
                            </div>
                          </td>
                          <td className="py-7 pr-4">{item?.project?.name}</td>
                          <td className="py-7 pr-4">
                            {item?.payment_gateway?.name}
                          </td>

                          <td className="py-7 pr-4">{item?.created_at}</td>
                          <td className="py-7">
                            <div className="flex items-center">
                              <div className="ml-2 flex">
                                <div className="flex">
                                  <div className="relative">
                                    <button
                                      onClick={() => handleRefund(item?.id)}
                                      type="button"
                                      className={` ${
                                        paymentId == item?.id &&
                                        showAction &&
                                        "text-gray-700 bg-gray-50"
                                      } inline-flex px-2 py-1 border-[1px] border-[#f0f0f0] rounded-md items-center justify-center h-full text-gray-600  hover:text-gray-700  hover:bg-gray-50`}
                                    >
                                      <LogoutIcon sx={{ fontSize: "20px" }} />
                                      <ArrowDropDownIcon />
                                    </button>

                                    <div
                                      className={`${
                                        paymentId == item?.id && showAction
                                          ? "visible"
                                          : "invisible"
                                      }  absolute left-[-67px] z-10 min-w-32  mt-1 origin-top-right bg-[white] border border-gray-100 rounded-md shadow-lg`}
                                    >
                                      <div className="p-2 ">
                                        {allPermission.includes(
                                          "Payment View"
                                        ) && (
                                          <>
                                            <button
                                              onClick={toggleDetailsDrawer}
                                              className="block w-full cursor-pointer px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 text-left"
                                            >
                                              Details
                                            </button>
                                            {/^partial/.test(
                                              item?.transaction_id
                                            ) ? (
                                              ""
                                            ) : (
                                              <button
                                                onClick={() =>
                                                  setIsPaymentDrawerOpen(true)
                                                }
                                                className="block w-full cursor-pointer px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 text-left"
                                              >
                                                Add Payment
                                              </button>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="bg-[#E5E7F1] rounded my-5">
                <h3 className="font-medium text-sm py-4 flex items-center pl-10 gap-x-3">
                  <span>
                    {" "}
                    <Dollars />{" "}
                  </span>{" "}
                  <span>Total Amount:</span> ${allData?.total_amount}
                </h3>
              </div>
              <div className="flex justify-end items-center">
                {links?.map((item, idx) => (
                  <button
                    key={idx}
                    onClick={(e) =>
                      getAllPayment(isFilter ? `${item.url}&` : item.url)
                    }
                    className={`${
                      item?.active &&
                      " !bg-gradient-to-r from-[#588CFF] to-[#2C4DFF] !text-white"
                    } first:rounded-r-none  first:w-auto first:px-2 last:px-2 last:w-auto w-[33px] rounded-md h-[33px] flex justify-center items-center last:rounded-l-none cursor-pointer border-2 bg-[#F1F1F1] py-2 text-[#767676] mx-1 border-[#F1F1F1]`}
                    dangerouslySetInnerHTML={{
                      __html: `${item.label}`,
                    }}
                  ></button>
                ))}
              </div>
            </>
          ) : (
            <LoadingIconGray />
          )}
        </div>

        <ImportPayment
          token={token}
          isOpenImportPayment={isOpenImportPayment}
          setIsOpenImportPayment={setIsOpenImportPayment}
        />

        <PaymentDetails
          paymentDetailsLoading={paymentDetailsLoading}
          paymentDetails={paymentDetails}
          isOpen={isDetailsOpen}
          toggleDrawer={toggleDetailsDrawer}
        />
        <PartialPayment
          paymentDetailsLoading={paymentDetailsLoading}
          paymentDetails={paymentDetails}
          isOpen={isPaymentDrawerOpen}
          toggleDrawer={() => setIsPaymentDrawerOpen(false)}
          handleDataUpdate={() =>
            getAllPayment(`${process.env.REACT_APP_BASE_URL}/payment-list`)
          }
        />
      </div>
    </>
  );
};

export default Payment;
