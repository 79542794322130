import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import CustomDropDown from "../CustomDropDown/CustomDropDown";
const GatewayForm = ({
  name,
  drawerName,
  isOpen,
  toggleDrawer,
  handleSubmit,
  setName,
  description,
  setDescription,
  status,
  setStatus,
}) => {
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (status === 1) {
      setSelected({ value: 1, label: "Enable" });
    } else {
      setSelected({ value: 0, label: "Disable" });
    }
  }, [status]);

  return (
    <Drawer open={isOpen} onClose={toggleDrawer} direction="right" size="600px">
      <div className="px-12 pt-12">
        <h4 className="flex items-center gap-x-[10px] text-lg font-bold">
          {drawerName}
        </h4>
        <hr className="mt-3 mb-[45px] border-[1px] bg-[#E0E0E0]" />
        <form onSubmit={(e) => handleSubmit(e)} className="text-[#2C3C57]">
          <label className="text-sm font-semibold flex items-center">
            Name
            <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
          </label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            className="border-[1px] w-full  py-[10px] px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
            placeholder="Name"
          />
          <label className="text-sm font-semibold flex items-center">
            Description
            <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
          </label>
          <input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            className="border-[1px] w-full  py-[10px] px-7 rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
            placeholder="Description"
          />
          <label className="text-sm font-semibold flex items-center">
            Status
          </label>
          <CustomDropDown
            value={selected}
            onChange={(e) => setStatus(e.value)}
            options={[
              { value: 1, label: "Enable" },
              { value: 0, label: "Disable" },
            ]}
            placeholder="Status"
            className="border-[1px] w-full   rounded-[10px] mt-3 mb-[25px] focus:outline-[1px] outline-[#2C4DFF]"
          />
          <div className="text-center mt-6">
            <button
              className={` flex items-center justify-center bg-[#2C4DFF] text-white active:bg-blueGray-600 text-sm font-semibold  px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150`}
              type="submit"
            >
              <span className="ml-5">{drawerName}</span>
            </button>
          </div>
        </form>
      </div>
    </Drawer>
  );
};

export default GatewayForm;
