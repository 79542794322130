import React, { useEffect, useState } from "react";
import HeatMap from "react-heatmap-grid";
import LoadingIconGray from "../../components/Common/LoadingGray";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import { ArrowRight, Filter } from "../../assets/svg/SVG";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";

const xLabels = [
  ...Array.from({ length: 24 }, (_, i) => `${(i + 1).toString()}:00`),
  "Total",
];

export default function Dashboard() {
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [yLabels, setYLabels] = useState([]);
  const [data, setData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [allGateway, setAllGateway] = useState();
  const [gatewayId, setGatewayId] = useState();

  const getAllPaymentGateway = async (url) => {
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setAllGateway(data?.data?.payment_gateway?.data);
    } catch (error) {
      console.log("error :", error);
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    // Calculate the fromDate and toDate based on the current date
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    setFromDate(getFormattedDate(sevenDaysAgo));
    setToDate(getFormattedDate(currentDate));

    if (token) {
      fetchHeatMapData(`${process.env.REACT_APP_BASE_URL}/payment-heatmap?`);
      getAllPaymentGateway(`${process.env.REACT_APP_BASE_URL}/payment-gateway`);
    }
  }, [token]);

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchHeatMapData = async (URL, isReset = false) => {
    setLoading(true);

    if (fromDate) {
      URL = URL + "from_date=" + fromDate + "&";
    }
    if (toDate) {
      URL = URL + "to_date=" + toDate + "&";
    }

    if (gatewayId && !isReset) {
      URL = URL + "gateway_id=" + gatewayId?.value + "&";
    }

    URL = URL.slice(0, -1);

    try {
      const response = await fetch(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();

      const dates = Object.keys(result?.data);
      const heatmapData = Object.values(result?.data);

      setYLabels(dates);
      setData(heatmapData);
      setLoading(false);
    } catch (error) {
      console.log("error :", error);
      setLoading(false);
    }
  };

  const handleReset = () => {
    setGatewayId("");
    fetchHeatMapData(
      `${process.env.REACT_APP_BASE_URL}/payment-heatmap?`,
      true
    );
  };

  return (
    <div className="w-full">
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
        <div className="flex justify-between mb-4">
          <h3 className="text-2xl font-medium text-[#000000] flex items-center gap-x-5">
            <img src="/payment.svg" alt="" /> <span>Payment Heatmap</span>
            <span className="text-sm -ml-2">{"(UTC 0)"}</span>
          </h3>
          <div className="flex">
            <button
              onClick={() => setIsFilter(!isFilter)}
              className="border-[#2A7FFE] ml-4 border-[1px] flex items-center  text-[#646464] rounded text-[15px] font-medium hover:scale-105 ease-in-out delay-150 py-[8px] px-[17px]"
            >
              <Filter />
              <span className="mx-[10px] text-lg">Filter</span>
              <span className={`${isFilter ? "" : "rotate-180"}`}>
                <ArrowRight />
              </span>
            </button>
          </div>
        </div>

        {isFilter && (
          <div className=" bg-[#F8F9FF] px-8 py-8 rounded-md  mb-10">
            <div className="flex items-center flex-wrap gap-y-4 2xl:gap-y-6">
              <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                <CustomDropDown
                  value={gatewayId}
                  onChange={(value) => setGatewayId(value)}
                  options={allGateway?.map((option) => {
                    return { value: option?.id, label: option?.name };
                  })}
                  placeholder="Payment Gateway Id"
                  className="placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full ease-linear transition-all duration-150 border-[#D7D7D7] border-[1px] focus:outline-[#635bff]"
                />
              </div>
              <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                <input
                  value={fromDate}
                  type="text"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="px-3 py-[10px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
                  placeholder="Start Date"
                />
              </div>
              <div className="w-full md:w-1/2 lg:w-4/12 2xl:w-3/12 px-4">
                <input
                  value={toDate}
                  type="text"
                  onChange={(e) => setToDate(e.target.value)}
                  className="px-3 py-[10px] placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  w-full border-[#D7D7D7] border-[1px] focus:outline-[#635bff] placeholder-[#0F223C]"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  placeholder="End Date"
                />
              </div>
              <div className="w-full mx-4 mt-5 flex gap-x-8">
                <button
                  onClick={(e) =>
                    fetchHeatMapData(
                      `${process.env.REACT_APP_BASE_URL}/payment-heatmap?`
                    )
                  }
                  className="bg-[#2A7FFE] text-white text-sm px-7 py-2 rounded font-normal hover:scale-105 ease-in-out delay-150 flex justify-center items-center"
                >
                  <SearchIcon />
                  <span className="ml-2 text-lg">Search Result</span>
                </button>
                <button
                  onClick={() => handleReset()}
                  className="bg-[#F2F2F2] text-[#3E3E3E] px-4 py-2 rounded text-sm hover:scale-105 ease-in-out delay-150 flex justify-center items-center"
                >
                  <RestartAltIcon />
                  <span className="ml-2 text-lg">Reset</span>
                </button>
              </div>
            </div>
          </div>
        )}

        {!loading ? (
          <>
            <div className="overflow-auto">
              <HeatMap
                xLabels={xLabels}
                yLabels={yLabels}
                yLabelWidth={120}
                data={data}
                yLabelsStyle={() => ({
                  fontSize: "4rem",
                  textTransform: "uppercase",
                  color: "#FFF",
                  background:"green"
                })}
                squares
                height={60}
                cellStyle={(background, value, min, max, data, x, y) => {
                  let cellBackground;
                  if (value > 0 && value <= 6000) {
                    let newMin = 255;
                    let newRange = 155;
                    let oldRange = 6000;
                    let oldMin = 0;

                    let rgb = Math.trunc(
                      newMin - ((value - oldMin) * newRange) / oldRange
                    );
                    cellBackground = `rgb(${rgb},0,0)`;
                  } else if (value > 6000 && value <= 25000) {
                    let newMin = 255;
                    let newRange = 155;
                    let oldRange = 25000;
                    let oldMin = 6000;

                    let rgb = Math.trunc(
                      newMin - ((value - oldMin) * newRange) / oldRange
                    );

                    cellBackground = `rgb(0,${rgb}, 0)`;
                  } else if(value==0) {
                    cellBackground = `rgb(255,0,0)`;
                  }else{
                    cellBackground = `rgb(32,178,170)`;
                  }
                  return {
                    background: cellBackground,
                    fontSize: "11px",
                  };
                }}
                cellRender={(value) => value && `$${value}`}
              />
            </div>
          </>
        ) : (
          <LoadingIconGray />
        )}
      </div>
    </div>
  );
}
