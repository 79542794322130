import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import Toggle from "react-toggle";
const CryptoBaseForm = ({
  name,
  drawerName,
  isOpen,
  toggleDrawer,
  handleSubmit,
  crypto,
  cryptoIdx,
  handleAllCryptos,
  allCryptos,
  allPermission,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [token, setToken] = useState();

  const handleNetwork = async (_netIdx, _id, _status, _for) => {
    if (_for === "network" && token) {
      setDisabled(true);

      const url = `${process.env.REACT_APP_BASE_URL}/crypto-currencies/${crypto.id}`;

      const allCryptosCopy = [...allCryptos];
      allCryptosCopy[cryptoIdx].networks[_netIdx].status = _status;

      const isAnyNetActiveNow = allCryptosCopy[cryptoIdx].networks.find(
        (item) => item.status === "ACTIVE"
      );

      if (!isAnyNetActiveNow) allCryptosCopy[cryptoIdx].status = "INACTIVE";
      else allCryptosCopy[cryptoIdx].status = "ACTIVE";

      try {
        const reqObj = {
          status: allCryptosCopy[cryptoIdx].status,
          networks: allCryptosCopy[cryptoIdx].networks,
        };
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(reqObj),
        });
        const data = await response.json();
        handleAllCryptos(allCryptosCopy);
        setDisabled(false);
      } catch (error) {
        console.log("error :", error);
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  }, []);

  useEffect(() => {
    if (!allPermission.includes("Crypto Currency Update Access"))
      setDisabled(true);
  }, [allPermission]);

  return (
    <Drawer open={isOpen} onClose={toggleDrawer} direction="right" size="600px">
      <div className="px-12 pt-12">
        <h4 className="flex items-center gap-x-[10px] text-lg font-bold">
          {drawerName}
        </h4>
        <hr className="mt-3 mb-[45px] border-[1px] bg-[#E0E0E0]" />
        <form onSubmit={(e) => handleSubmit(e)} className="text-[#2C3C57]">
          <label className="text-sm font-semibold flex items-center">
            Name
            <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
          </label>
          <div className="mt-1">{crypto?.name}</div>
          <label className="mt-4 text-sm font-semibold flex items-center">
            Networks
            <span className="pl-2 pt-1 text-[#FF0000] inline-block">*</span>
          </label>
          <div className="mt-1">
            {crypto?.networks?.map((network, idx) => (
              <div
                className="w-full border-b  border-[#EDEFF3] whitespace-nowrap text-sm hover:bg-gray-200 grid grid-cols-[2fr_1fr] px-4"
                key={network?.iso}
              >
                <div className="py-5 max-w-[18rem] tooltip relative">
                  <div className="truncate">{network?.name}</div>

                  <span className="tooltip-text absolute z-10 top-16 left-1/2 transform -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-sm rounded-md">
                    {network?.name}
                  </span>
                </div>
                <div className="py-5">
                  <Toggle
                    id="network-status"
                    checked={network.status === "ACTIVE" ? true : false}
                    onChange={() =>
                      handleNetwork(
                        idx,
                        network?.iso,
                        network.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                        "network"
                      )
                    }
                    disabled={disabled}
                  />
                </div>
              </div>
            ))}
          </div>
        </form>
      </div>
    </Drawer>
  );
};

export default CryptoBaseForm;
