const Spinner = (props) => {
  return (
    <div className="fixed w-full top-0 left-0 lg:left-[218px] right-0 bottom-0 lg:w-[calc(100%-206px)] h-screen overflow-hidden opacity-95 flex flex-col items-center justify-center bg-white !z-[99999]">
      <div className="loader ease-linear rounded-full border-[5px] border-t-[5px] border-black h-12 w-12 mb-4"></div>
      <p className="text-5xl text-purple font-semibold">
        {props.children || "Loading..."}
      </p>
    </div>
  );
};

export default Spinner;
