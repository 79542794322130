import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";
import LoadingIconGray from "../components/Common/LoadingGray";
import Sidebar from "../components/Sidebar/Sidebar";
import CryptoBase from "../views/admin/CryptoBase/CryptoBase";
import Customer from "../views/admin/Customer";
import Dashboard from "../views/admin/Dashboard";
import ExtraCharge from "../views/admin/ExtraCharge";
import Payment from "../views/admin/Payment";
import PaymentGateway from "../views/admin/PaymentGatway";
import Project from "../views/admin/Project";
import Refund from "../views/admin/Refund";
import Role from "../views/admin/Role";
import User from "../views/admin/User";
import DownloadReports from "../views/admin/DownloadReports";

const Admin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [permissionList, setPermissionList] = useState([]);

  const fetchUerInfo = async (token) => {
    setLoading(true);
    let res = await fetch(`${process.env.REACT_APP_BASE_URL}/me`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    let result = await res.json();
    if (result?.data?.length == 0) {
      toast(result?.message);
      localStorage.removeItem("token");
      navigate("/auth/login");
    } else {
      console.log("result?.data", result?.data);
      setUserInfo(result?.data?.user);
      setPermissionList(result?.data?.permissions);
    }
    setLoading(false);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token == null) navigate("/auth/login");
    else {
      fetchUerInfo(token);
    }
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <LoadingIconGray />
        </div>
      ) : (
        <div>
          <Sidebar sidebarPermissions={permissionList} />
          <div className="relative md:ml-[218px] bg-blueGray-100">
            <AdminNavbar userInfo={userInfo} />
            <div className="px-4 md:px-10 mx-auto w-full py-6">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                {permissionList.includes("Role View") && (
                  <Route
                    path="/role"
                    element={<Role allPermission={permissionList} />}
                  />
                )}
                {permissionList.includes("User View") && (
                  <Route
                    path="/user"
                    element={<User allPermission={permissionList} />}
                  />
                )}
                {permissionList.includes("Customer View") && (
                  <>
                    <Route
                      path="/customer"
                      element={<Customer allPermission={permissionList} />}
                    />
                  </>
                )}
                {permissionList.includes("Refund Access") && (
                  <>
                    <Route
                      path="/refund"
                      element={<Refund allPermission={permissionList} />}
                    />
                  </>
                )}
                {permissionList.includes("Payment View") && (
                  <>
                    <Route
                      path="/payment"
                      element={<Payment allPermission={permissionList} />}
                    />
                  </>
                )}

                {permissionList.includes("Extra Charge View") && (
                  <>
                    <Route
                      path="/extra-charge"
                      element={<ExtraCharge allPermission={permissionList} />}
                    />
                  </>
                )}

                {permissionList.includes("Gateway View") && (
                  <Route
                    path="/payment-gateway"
                    element={<PaymentGateway allPermission={permissionList} />}
                  />
                )}
                {permissionList.includes("Project View") && (
                  <Route
                    path="/project"
                    element={<Project allPermission={permissionList} />}
                  />
                )}
                {permissionList.includes("Crypto Currency Access") && (
                  <Route
                    path="/crypto-base"
                    element={<CryptoBase allPermission={permissionList} />}
                  />
                )}

                {permissionList.includes("Can Download Reports") && (
                  <Route
                    path="/download-reports"
                    element={<DownloadReports allPermission={permissionList} />}
                  />
                )}

                {/* 
                   <Route
                          path="*"
                          element={<Navigate to="/admin/dashboard" replace />}
                        />
                 */}
              </Routes>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Admin;
